<div class="products">
  @for (product of products(); track product) {
    <div class="product-row d-flex justify-content-between align-items-start">
      <div class="d-flex justify-content-start align-items-start">
        <span class="i-office product-icon"></span>
        <div>
          <h4 class="header-12">
            {{ "MARINA_PRODUCT_." + product.productType | translate }}
          </h4>
          <p class="quantity">
            {{ product.quantity }}
            <small>{{ product.unit | translate }} </small>
          </p>
        </div>
      </div>
      <dl class="description-list">
        <dt>
          {{ "TOTAL_AMOUNT" | translate }}
        </dt>
        <dd>
          <strong>{{ product.totalPriceGross | currency: currency() }}</strong>
        </dd>
      </dl>
    </div>
  }
</div>

<div class="summary d-flex justify-content-between align-items-start">
  <div>
    <h3>{{ "SUMMARY" | translate }}</h3>
    <p class="color-secondary text-sm mt-2 fw-600">
      {{ products().length }} {{ (products().length === 1 ? "PRODUCT" : "PRODUCTS") | translate }}
    </p>
  </div>
  <dl class="description-list">
    <dt>
      {{ "PRODUCTS_&_SERVICES" | translate }}
    </dt>
    <dd>
      <strong>{{ totalProducts() | currency: currency() }}</strong>
    </dd>
    <dt>
      {{ "TAXES" | translate }}
    </dt>
    <dd>
      <strong>{{ totalTaxes() | currency: currency() }}</strong>
    </dd>
    <dt class="total">
      {{ "TOTAL_AMOUNT" | translate }}
    </dt>
    <dd>
      <strong>{{ total() | currency: currency() }}</strong>
    </dd>
  </dl>
</div>
