import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MmsOffersTableBase } from '../mms-offers-table.base';

@Component({
  selector: 'dm-mms-offers-mms-dashboard-offers-table',
  templateUrl: './mms-dashboard-offers-table.component.html',
  styleUrl: './mms-dashboard-offers-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsDashboardOffersTableComponent extends MmsOffersTableBase {}
