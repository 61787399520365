import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'dm-mms-ui-page-header',
  templateUrl: './mms-ui-page-header.component.html',
  styleUrl: './mms-ui-page-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsUiPageHeaderComponent {
  routes = input<{ route: string; label: string }[]>();
  subHeader = input<string>();
  header = input.required<string>();
}
