import { QuoteStatus } from '@dm-workspace/types';
import { MmsQuotesTablesRoutesListData } from '../../../../../mms-enquiry/src/lib/mms-enquiry.routes';

export const viewTypesList = ['readyToSend', 'sent', 'accepted', 'declined', 'all'];

export const offersTablesViewConfig: Record<string, MmsQuotesTablesRoutesListData> = {
  readyToSend: {
    availableFilters: ['paymentStatus', 'createdAt', 'quoteStatus'],
    quoteStatusValues: [QuoteStatus.READY_TO_SEND],
    tableColumns: [
      'STATUS',
      'CREATED_AT',
      'SERVICE_TYPE',
      'CUSTOMER',
      'RESOURCE',
      'BOAT',
      'PERIOD',
      'VALID_UNTIL',
      'PAYMENT_STATUS',
      'ACTIONS',
    ],
  },
  sent: {
    availableFilters: ['lastSendDate', 'lastSeenDate'],
    quoteStatusValues: [QuoteStatus.SENT],
    tableColumns: [
      'STATUS',
      'CREATED_AT',
      'SERVICE_TYPE',
      'CUSTOMER',
      'RESOURCE',
      'BOAT',
      'PERIOD',
      'VALID_UNTIL',
      'ACTIONS',
    ],
  },
  accepted: {
    availableFilters: ['paymentStatus', 'acceptedAt'],
    quoteStatusValues: [QuoteStatus.ACCEPTED],
    tableColumns: [
      'STATUS',
      'CREATED_AT',
      'SERVICE_TYPE',
      'CUSTOMER',
      'RESOURCE',
      'BOAT',
      'PERIOD',
      'ACCEPTED_AT',
      'ACTIONS',
    ],
  },
  declined: {
    availableFilters: ['declinedAt'],
    quoteStatusValues: [QuoteStatus.DECLINED],
    tableColumns: [
      'STATUS',
      'CREATED_AT',
      'SERVICE_TYPE',
      'CUSTOMER',
      'RESOURCE',
      'BOAT',
      'PERIOD',
      'DECLINED_AT',
      'ACTIONS',
    ],
  },
  all: {
    availableFilters: ['paymentStatus', 'createdAt', 'quoteStatus'],
    quoteStatusValues: [
      QuoteStatus.NEW,
      QuoteStatus.WAITING_LIST,
      QuoteStatus.PENDING_FOR_APPROVAL,
      QuoteStatus.DRAFT,
      QuoteStatus.READY_TO_SEND,
      QuoteStatus.SENT,
      QuoteStatus.ACCEPTED,
      QuoteStatus.DECLINED,
      QuoteStatus.REVISED,
      QuoteStatus.CANCELLED,
      QuoteStatus.AWAITS_AUTO_APPROVAL,
    ],
    tableColumns: [
      'STATUS',
      'CREATED_AT',
      'TYPE',
      'CUSTOMER',
      'RESOURCE',
      'BOAT',
      'PERIOD',
      'VALID_UNTIL',
      'PAYMENT_STATUS',
      'ACTIONS',
    ],
  },
};
