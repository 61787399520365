import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { BerthAvailabilityReservationDto, BoatMovementOperationForStatus } from '@dm-workspace/types';

import { BerthDrawerDetailsClass } from '../berth-drawer-details.class';
import { MapBerthClass } from '../../../berths.class';

@Component({
  selector: 'dm-map-map-berth-details-reservations-list',
  templateUrl: './map-berth-details-reservations-list.component.html',
  styleUrl: '../map-berth-details-list-styles.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapBerthDetailsReservationsListComponent extends BerthDrawerDetailsClass {
  berth = input.required<MapBerthClass>();
  extendedBookingsData = input.required<BerthAvailabilityReservationDto[]>();
  hasItemsToShow = computed(() => this.berth().data.bookings.length || this.berth().data.occupancy.length);
  marinaCode = input.required<string>();

  bookingListBoats = computed(() => {
    const occupancyWithoutBooking = this.berth().data.occupancy.filter(
      (boat) => !this.berth().data.bookings.some((booking) => booking.boatId === boat.boatId)
    );
    return [...(this.extendedBookingsData() ?? []), ...(occupancyWithoutBooking as BerthAvailabilityReservationDto[])];
  });

  protected readonly BoatMovementOperationForStatus = BoatMovementOperationForStatus;
}
