import { ChangeDetectionStrategy, Component, inject, Input, OnInit, signal } from '@angular/core';
import { Contact, LOCALIZATION_LANGUAGES, SUPPORTED_LANGUAGES } from '@dm-workspace/types';
import { MmsCustomerApiService } from '@dm-workspace/data-access';
import { NotificationService } from '@dm-workspace/notification';
import { ConfirmResultModal } from '@dm-workspace/ui';
import { ApiValidatorService, FormUtils } from '@dm-workspace/forms';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'dm-mms-contact-change-email-modal',
  templateUrl: './mms-contact-change-language-modal.component.html',
  styleUrls: ['./mms-contact-change-language-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsContactChangeLanguageModalComponent
  extends ConfirmResultModal<LOCALIZATION_LANGUAGES>
  implements OnInit
{
  @Input({ required: true }) public contact: Contact;
  protected readonly apiCalling = signal<boolean>(false);
  private customerApiService = inject(MmsCustomerApiService);
  protected readonly notification = inject(NotificationService);
  protected readonly apiValidator = inject(ApiValidatorService);
  protected readonly Languages = SUPPORTED_LANGUAGES;
  protected readonly form = new FormGroup({
    language: new FormControl(null, Validators.required),
  });

  constructor() {
    super();
  }

  public ngOnInit() {
    if (this.contact.language) {
      this.form.patchValue(this.contact);
    }
  }

  protected buttonConfirmClick() {
    if (this.apiCalling() || !FormUtils.isValid(this.form)) {
      return;
    }
    this.apiCalling.set(true);

    const { language } = this.form.getRawValue();

    return this.customerApiService
      .updateIndividual(this.contact.customerId, {
        language,
      })
      .pipe(finalize(() => this.apiCalling.set(false)))
      .subscribe({
        next: () => {
          this.notification.openSuccess();
          this.onConfirm(language);
        },
        error: (res: HttpErrorResponse) => {
          const notificationContent = this.apiValidator.getApiNotificationError(res);
          this.notification.openError(notificationContent);
        },
      });
  }
}
