<div class="modal-header">
  <h2 class="title">
    @if (!isUsed) {
      {{ "TURN_ON_THE_SOCKET" | translate }}
    } @else {
      {{ "TURN_OFF_THE_SOCKET" | translate }}
    }
  </h2>
</div>
<div class="modal-body">
  @if (!isUsed) {
    <p>{{ "TURN_ON_THE_SOCKET_DESCRIPTION" | translate }}</p>
  } @else {
    <p>{{ "TURN_OFF_THE_SOCKET_DESCRIPTION" | translate }}</p>
    <p class="mt-2">{{ "TURN_OFF_THE_SOCKET_DESCRIPTION_WARNING" | translate }}</p>
  }
  <form [formGroup]="socketForm">
    <div class="row">
      <dm-form-group class="w-50 my-3">
        <label>{{ "PEDESTAL" | translate }}</label>
        <input dmForm formControlName="pedestal" type="text" [placeholder]="'PEDESTAL' | translate" />
        <dm-form-error-message controlName="pedestal" />
      </dm-form-group>
      <dm-form-group class="w-50 my-3">
        <label>{{ "SOCKET_ID" | translate }}</label>
        <input dmForm formControlName="socketId" type="text" [placeholder]="'SOCKET_ID' | translate" />
        <dm-form-error-message controlName="socketId" />
      </dm-form-group>
    </div>
    @if (socketForm.controls.bookingId) {
      <dm-ui-horizontal-line />
      <dm-form-group class="w-100 my-3">
        <label>{{ "Booking ID" | translate }}</label>
        <input dmForm formControlName="bookingId" type="text" [placeholder]="'Booking ID' | translate" />
        <dm-form-error-message controlName="bookingId" />
      </dm-form-group>
    }
  </form>
</div>

<div class="modal-footer justify-content-between d-flex">
  <button type="button" class="btn btn-tertiary btn-narrow" (click)="dismissModal()">
    {{ "CANCEL" | translate }}
  </button>
  <button
    class="btn btn-primary btn-default"
    type="button"
    (click)="onSubmit()"
    [disabled]="apiCalling() || socketForm.invalid"
  >
    <dm-ui-spinner class="me-2" [showSpinner]="apiCalling()" />
    @if (!isUsed) {
      {{ "TURN_ON" | translate }}
    } @else {
      {{ "TURN_OFF" | translate }}
    }
  </button>
</div>
<dm-ui-overflow-spinner [showSpinner]="apiCalling()" type="absolute" />
