import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'dm-mms-quote-details-nav',
  templateUrl: './mms-quote-details-nav.component.html',
  styleUrl: './mms-quote-details-nav.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsQuoteDetailsNavComponent {
  @Input() routes: { route: string; label: string }[];
}
