<div [style.max-width.px]="720">
  @if (offer()) {
    <!--    MAYBE TO BE USED-->
    <!--    <dm-ui-alert color="primary" class="mb-5" *ngIf="StatusesWithAlerts.includes(offer().quoteStatus)">-->
    <!--      <dm-ui-info>-->
    <!--        <span class="i-exclamation-mark-circle" icon></span>-->
    <!--        <h4 class="header-12 fw-600 mb-1">{{ "ACTIVATE_ENQUIRE_BEFORE_SENDING" | translate }}</h4>-->
    <!--        <p class="typo-body-11 color-kashmir">-->
    <!--          {{ "FINISH_THE_ACTIVATION_PROCESS_IN_THE_CRM_VAILABLE_YET_RETRY_IN_FEW_MINUTES" | translate }}-->
    <!--        </p>-->
    <!--      </dm-ui-info>-->
    <!--    </dm-ui-alert>-->
    <dm-ui-card color="blue" class="position-relative">
      <dm-ui-card-header icon="i-summary"
        ><h3 class="header-16">
          {{ "CURRENT_OFFER_DETAILS" | translate }}
        </h3></dm-ui-card-header
      >

      <dm-ui-card-body>
        <dl class="description-list">
          <dt>{{ "OFFER_NAME" | translate }}</dt>
          <dd>{{ offer().name }}</dd>
          <dt>{{ "BOOKING_ID" | translate }}</dt>
          <dd>{{ offer().booking?.humanReadableId }}</dd>
          <dt>{{ "BERTH" | translate }}</dt>
          <dd>{{ offer().proposedResource?.name }}</dd>
          <dt>{{ "CUSTOMER" | translate }}</dt>
          <dd>
            <a [routerLink]="['/customers', offer().customer?.id]" returnUrlCurrentPath class="link">
              {{ offer().customer?.name | empty }}
            </a>
          </dd>
          <dt>{{ "BOAT" | translate }}</dt>
          <dd>
            <a [routerLink]="['/boats', offer().boat?.id]" returnUrlCurrentPath class="link">
              {{ offer().boat?.name | empty }}
            </a>
          </dd>
          <dt>{{ "PERIOD" | translate }}</dt>
          <dd>
            {{ offer().startDate | dateFormat }} - {{ offer().endDate | dateFormat }}
            <small *ngIf="nights$ | async as nights" class="color-secondary"
              ><strong>{{ nights }}</strong> {{ (nights > 1 ? "NIGHTS" : "NIGHT") | translate }}</small
            >
          </dd>
          <dt>{{ "CREATED_AT" | translate }}</dt>
          <dd>
            {{ offer().createdAt | dateFormat: DATE_FORMAT }}
            <small class="color-secondary"
              ><strong>{{ offer().createdAt | dateDiff: todayDate }}</strong> {{ "DAYS_AGO" | translate }}</small
            >
          </dd>

          <dt>{{ "TOTAL" | translate }}</dt>
          <dd>{{ offer().totalPriceGross | currency: offer().currency | empty }}</dd>

          <dt>{{ "PAYMENT_STATUS" | translate }}</dt>
          <dd>
            @if (offer().order) {
              <dm-ui-tag [color]="offer().order.paymentStatus | paymentStatusTagColor"
                >{{ offer().order.paymentStatus | orderPaymentStatusTranslateKey | translate }}
              </dm-ui-tag>
            } @else {
              -
            }
          </dd>
        </dl>
      </dm-ui-card-body>
    </dm-ui-card>
  }
</div>
