import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BoatMovementOperation } from '@dm-workspace/types';

export type MmsBoatMovementForm = FormGroup<{
  operation: FormControl<BoatMovementOperation>;
  startsAt?: FormControl<string>;
  endsAt?: FormControl<string>;
  gapStart?: FormControl<string>;
  gapEnd?: FormControl<string>;
  sourceId?: FormControl<string>;
  destinationId?: FormControl<string>;
  allowsBerthReuse?: FormControl<boolean>;
  transferBooking: FormControl<boolean>;
}>;

export type MmsBoatMovementFormValue = Required<ReturnType<MmsBoatMovementForm['getRawValue']>>;

export class MmsBoatMovementFormBuilder {
  constructor(private form: MmsBoatMovementForm) {}

  static buildForm(initValue?: Partial<MmsBoatMovementFormValue>): MmsBoatMovementForm {
    return new FormGroup({
      operation: new FormControl<BoatMovementOperation>(initValue?.operation, { validators: [Validators.required] }),
      transferBooking: new FormControl<boolean>(initValue?.transferBooking || false),
    });
  }

  changeByOperation(operation: BoatMovementOperation, sourceId?: string) {
    this.removeStartAt()
      .removeAllowsBerthReuse()
      .removeDestinationId()
      .removeEndsAt()
      .removeSourceId()
      .removeGap()
      .removeBookingUpdate();
    if (!operation) {
      return;
    }

    const startsAtDefaultValue = new Date().toISOString();

    switch (operation) {
      case BoatMovementOperation.ARRIVAL: {
        const startAtValue = new Date(new Date().setHours(14, 0, 0, 0)).toISOString();
        this.addStartAt(startAtValue).addDestinationId();
        break;
      }
      case BoatMovementOperation.DRY_DOCK_LIFT: {
        this.addStartAt(startsAtDefaultValue)
          .addSourceId(sourceId)
          .addDestinationId()
          .addAllowsBerthReuse()
          .addStartAt()
          .addEndsAt();
        break;
      }
      case BoatMovementOperation.DEPARTURE: {
        const startAtValue = new Date(new Date().setHours(12, 0, 0, 0)).toISOString();
        this.addStartAt(startAtValue).addSourceId(sourceId);
        break;
      }
      case BoatMovementOperation.DRY_DOCK_LAUNCH: {
        this.addStartAt(startsAtDefaultValue).addSourceId().addDestinationId();
        break;
      }
      case BoatMovementOperation.CRUISING: {
        this.addStartAt(startsAtDefaultValue).addSourceId(sourceId).addStartAt().addEndsAt().addAllowsBerthReuse();
        break;
      }
      case BoatMovementOperation.INTERNAL_MOVEMENT: {
        this.addStartAt(startsAtDefaultValue).addSourceId(sourceId).addDestinationId();
        break;
      }
      case BoatMovementOperation.RETURN: {
        this.addStartAt(startsAtDefaultValue).addDestinationId();
        break;
      }
    }
  }

  overBookingChange(value: boolean): void {
    if (
      value &&
      (this.form.controls.operation.value === BoatMovementOperation.CRUISING ||
        this.form.controls.operation.value === BoatMovementOperation.DRY_DOCK_LIFT)
    ) {
      this.addGap();
      this.form.controls.endsAt.setValidators([Validators.required]);
    } else {
      this.removeGap();
      this.form.controls.endsAt.clearValidators();
    }
    this.form.controls.endsAt.updateValueAndValidity();
  }

  changeByBookingUpdate(value: boolean): void {
    if (!value && this.form.controls.operation.value === BoatMovementOperation.DRY_DOCK_LIFT) {
      this.form.controls.destinationId.clearValidators();
    } else {
      this.form.controls.destinationId.setValidators([Validators.required]);
    }
    this.form.controls.destinationId.updateValueAndValidity();
  }

  addStartAt(initValue?: string) {
    const control = new FormControl<string>(initValue, { validators: [Validators.required] });
    this.form.addControl('startsAt', control);
    return this;
  }

  removeStartAt() {
    this.form.removeControl('startsAt');
    return this;
  }

  addSourceId(initValue?: string) {
    const control = new FormControl<string>(initValue, { validators: this.getSourceIdValidators() });
    this.form.addControl('sourceId', control);
    return this;
  }

  getSourceIdValidators() {
    switch (this.form.value.operation) {
      case BoatMovementOperation.DRY_DOCK_LAUNCH: {
        return [];
      }
      default: {
        return [Validators.required];
      }
    }
  }

  getEndsAtValidators() {
    switch (this.form.value.operation) {
      case BoatMovementOperation.DRY_DOCK_LIFT: {
        return [];
      }
      case BoatMovementOperation.CRUISING: {
        return [];
      }
      default: {
        return [Validators.required];
      }
    }
  }

  removeSourceId() {
    this.form.removeControl('sourceId');
    return this;
  }

  addDestinationId(initValue?: string) {
    const control = new FormControl<string>(initValue, { validators: this.getDestinationIdValidators() });
    this.form.addControl('destinationId', control);
    return this;
  }

  getDestinationIdValidators() {
    switch (this.form.value.operation) {
      case BoatMovementOperation.DRY_DOCK_LIFT: {
        return [];
      }
      default: {
        return [Validators.required];
      }
    }
  }

  removeDestinationId() {
    this.form.removeControl('destinationId');
    return this;
  }

  addEndsAt() {
    this.form.addControl('endsAt', new FormControl<string>(null, { validators: this.getEndsAtValidators() }));
    return this;
  }

  removeEndsAt() {
    this.form.removeControl('endsAt');
    return this;
  }

  addGap(initValue?: { gapStart: string; gapEnd: string }) {
    this.form.addControl('gapStart', new FormControl<string>(initValue?.gapStart, [Validators.required]));
    this.form.addControl('gapEnd', new FormControl<string>(initValue?.gapEnd, [Validators.required]));
    return this;
  }

  removeGap() {
    this.form.removeControl('gapStart');
    this.form.removeControl('gapEnd');
    return this;
  }

  removeBookingUpdate() {
    this.form.controls.transferBooking.setValue(false);
    return this;
  }

  addAllowsBerthReuse() {
    this.form.addControl('allowsBerthReuse', new FormControl<boolean>(false));
    return this;
  }

  removeAllowsBerthReuse() {
    this.form.removeControl('allowsBerthReuse');
    return this;
  }
}
