import { NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { TranslateModule } from '@ngx-translate/core';
import { NgbDropdownModule, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { LibsFormsModule } from '@dm-workspace/forms';
import { UiModule } from '@dm-workspace/ui';
import { LibsSharedModule } from '@dm-workspace/shared';
import { MmsPanelBookingsModule } from '@dm-workspace/mms-panel/bookings';
import { MarinaMapModule } from '@dm-workspace/marina-map';
import { AddBookingAttachmentModalComponent } from '../../../shared/src/lib/modals/add-booking-attachment-modal/add-booking-attachment-modal.component';
import { MmsLayoutNotFoundViewComponent } from './views/mms-layout-not-found-view/mms-layout-not-found-view.component';
import { MmsLayoutMainViewComponent } from './views/mms-layout-main-view/mms-layout-main-view.component';
import { MmsLayoutUserActionsComponent } from './components/mms-layout-user-actions/mms-layout-user-actions.component';
import { MmsLayoutNavRoutesActiveDirective } from './directives/mms-layout-nav-routes-active.directive';
import { MmsMarinaSwitchComponent } from './components/mms-layout-marina-switch/mms-layout-marina-switch.component';
import { MmsPanelSharedModule } from '@dm-workspace/mms-panel/shared';
import { MmsLayoutMarinaCodeWrapperViewComponent } from './views/mms-layout-marina-code-wrapper-view/mms-layout-marina-code-wrapper-view.component';
import { MmsLayoutHeaderComponent } from './components/mms-layout-header/mms-layout-header.component';
import { MmsLayoutNavigationComponent } from './components/mms-layout-navigation/mms-layout-navigation.component';
import { MapUtilsModule } from '@dm-workspace/map-utils';
import { MmsLayoutExportMarinaEnquiryComponent } from './components/mms-layout-export-marina-enquiry/mms-layout-export-marina-enquiry.component';

@NgModule({
  declarations: [
    MmsLayoutMarinaCodeWrapperViewComponent,
    MmsLayoutMainViewComponent,
    MmsLayoutNotFoundViewComponent,
    MmsLayoutUserActionsComponent,
    AddBookingAttachmentModalComponent,
    MmsLayoutHeaderComponent,
    MmsLayoutNavigationComponent,
    MmsMarinaSwitchComponent,
    MmsLayoutNavRoutesActiveDirective,
    MmsLayoutExportMarinaEnquiryComponent,
  ],
  imports: [
    GoogleMapsModule,
    MarinaMapModule,
    LibsSharedModule,
    TranslateModule,
    NgbDropdownModule,
    RouterModule,
    ReactiveFormsModule,
    LibsFormsModule,
    UiModule,
    MmsPanelBookingsModule,
    MmsPanelSharedModule,
    MapUtilsModule,
    NgbInputDatepicker,
  ],
})
export class MmsLayoutModule {}
