<ng-container [formGroup]="form()">
  <div class="row gy-3">
    <dm-form-group class="col-5">
      <label>{{ "MOVEMENT_TYPE" | translate }}</label>
      <ng-select [clearable]="false" dmForm formControlName="operation" (change)="onChangeOperation($event)">
        @for (
          boatMovementOperation of BoatMovementOperation | keyvalue: NgForUtils.originalOrder;
          track boatMovementOperation.key
        ) {
          @if (operations() | arrayIncludes: boatMovementOperation.value) {
            <ng-option [value]="boatMovementOperation.value">
              {{ boatMovementOperation.key | boatMovementOperationTranslateKey | translate }}
            </ng-option>
          }
        }
      </ng-select>
      <dm-form-error-message [control]="form().controls.operation" />
    </dm-form-group>

    <div class="col-7">
      <div class="row">
        @if (form().controls.sourceId) {
          <dm-form-group class="col-6">
            <label>{{ sourceIdLabelTranslateKey$ | async | translate }}</label>
            <dm-form-select-typeahead
              dmForm
              typeahead="resources"
              formControlName="sourceId"
              bindLabel="name"
              bindValue="id"
              [resourceType]="sourceIdResourceType$ | async"
            />
            <dm-form-error-message [control]="form().controls.sourceId" />
          </dm-form-group>
        }

        @if (form().controls.destinationId) {
          <dm-form-group class="col-6">
            <label>{{ destinationIdLabelTranslateKey$ | async | translate }}</label>
            <dm-form-select-typeahead
              dmForm
              typeahead="resources"
              formControlName="destinationId"
              bindLabel="name"
              bindValue="id"
              [resourceType]="destinationIdResourceType$ | async"
            />
            <dm-form-error-message [control]="form().controls.destinationId" />
          </dm-form-group>
        }
      </div>
    </div>
  </div>

  <div class="row gy-3 mt-2">
    @if (form().controls.startsAt) {
      <dm-form-group class="col-6">
        <label>{{ startsAtLabelTranslateKey$ | async | translate }}</label>
        <dm-form-date-time-picker dmForm formControlName="startsAt" [maxDate]="todayDayString" />
        <dm-form-error-message [control]="form().controls.startsAt" />
      </dm-form-group>
    }

    @if (form().controls.endsAt) {
      <dm-form-group class="col-6">
        <label>{{ "RETURN_DATE" | translate }}</label>
        <dm-form-date-time-picker
          dmForm
          formControlName="endsAt"
          [defaultHour]="14"
          [minDate]="minReturnDate$ | async"
        />
        <dm-form-error-message [control]="form().controls.endsAt" />
      </dm-form-group>
    }
  </div>

  <dm-ui-horizontal-line />

  @if (form().controls.operation.value === BoatMovementOperation.CRUISING && form().controls.allowsBerthReuse) {
    <ng-container *ngTemplateOutlet="overBooking" />
  }

  @if (form().controls.operation.value === BoatMovementOperation.DRY_DOCK_LIFT) {
    @if (boatBookingId()) {
      <ng-container *ngTemplateOutlet="bookingUpdate" />
      <dm-ui-horizontal-line />
    }

    @if (form().controls.allowsBerthReuse) {
      <ng-container *ngTemplateOutlet="overBooking" />
    }
  }

  @if (form().controls.operation.value === BoatMovementOperation.INTERNAL_MOVEMENT && boatBookingId()) {
    <ng-container *ngTemplateOutlet="bookingUpdate" />
  }

  <ng-template #overBooking>
    <div class="d-flex flex-column">
      <h3 class="header-18">{{ "OVERBOOKING" | translate | titlecase }}</h3>
      <dm-form-group class="mt-3">
        <label class="fw-500 typo-body-s">
          <input
            type="checkbox"
            dmForm
            formControlName="allowsBerthReuse"
            (change)="onOverBookingChange($event)"
          />
          {{ "OVERBOOKING_BERTH_SHOULD_BE_AVAILABLE" | translate }}
        </label>
      </dm-form-group>
    </div>

    @if (form().controls.allowsBerthReuse.value) {
      <dm-ui-horizontal-line />

      <div class="row gy-3 mt-2">
        @if (form().controls.gapStart) {
          <dm-form-group class="col-6">
            <label>{{ "OVERBOOKING_AVAILABLE_FROM" | translate }}</label>
            <dm-form-date-time-picker
              dmForm
              formControlName="gapStart"
              [minDate]="form().controls.startsAt.value"
              [maxDate]="form().controls.gapEnd.value ? form().controls.gapEnd.value : form().controls.endsAt.value"
              [defaultHour]="null"
            />
            <dm-form-error-message [control]="form().controls.gapStart" />
          </dm-form-group>
        }

        @if (form().controls.gapEnd) {
          <dm-form-group class="col-6">
            <label>{{ "OVERBOOKING_AVAILABLE_TILL" | translate }}</label>
            <dm-form-date-time-picker
              dmForm
              formControlName="gapEnd"
              [minDate]="
                form().controls.gapStart.value ? form().controls.gapStart.value : form().controls.startsAt.value
              "
              [maxDate]="form().controls.endsAt.value"
              [defaultHour]="null"
            />
            <dm-form-error-message [control]="form().controls.gapEnd" />
          </dm-form-group>
        }
      </div>
    }
  </ng-template>

  <ng-template #bookingUpdate>
    <div class="d-flex flex-column">
      <h3 class="header-18">{{ "BOOKING_UPDATE" | translate }}</h3>
      <dm-form-group class="mt-3">
        <label class="fw-400 typo-body-s">
          <input type="checkbox" dmForm formControlName="transferBooking" (click)="onBookingUpdateClick($event)"/>
          {{ "DESTINATION_IN_RELATED_BOOKING" | translate }}
          <strong>
            {{ boatBookingId() }}
            {{
              form().controls.operation.value === BoatMovementOperation.INTERNAL_MOVEMENT
                ? ("DESTINATION_IN_RELATED_BOOKING_1_BERTH" | translate)
                : ("DESTINATION_IN_RELATED_BOOKING_1_LAND" | translate)
            }}
          </strong>
        </label>
      </dm-form-group>
    </div>
  </ng-template>

</ng-container>
