<google-map
  *ngIf="isLoaded$ | async as options"
  width="100%"
  [options]="options"
  [height]="height"
  [center]="center"
  (mapClick)="onMapClick()"
>
  <map-polygon *ngFor="let pier of piers" [options]="piersOptions" [paths]="pier | arrayToLatLng" />
  <ng-container *ngIf="!shouldShowAlerts">
    <map-polygon
      *ngFor="let multihull of multihullsPolygon"
      [paths]="multihull.poly | arrayToLatLng"
      [options]="multihull.color | multihullPolygonStyleByStatus"
    />
  </ng-container>
  @for (marker of piers$ | async; track marker) {
    <map-marker [options]="marker" (mapDblclick)="onDbClickPierName(marker.label.text)" />
  }
  @if (viewType === ViewTypes.pylons) {
    @for (pylon of pylons$ | async; track pylon.name) {
      <map-circle
        [options]="pylon | pylonCircle: showStatus"
        [center]="pylon.location"
        (circleClick)="selectPylon(pylon)"
      />
    }
  }
  <map-polygon
    *ngFor="let berth of berths"
    #berthM="mapPolygon"
    [paths]="berth.data.polygon | arrayToLatLng"
    [options]="berth | berthPolygonStyleByStatus: showStatus : viewType"
    (polygonClick)="onBerthPolygonClick(berthM, berth)"
    (polygonMouseover)="onBerthPolygonHover(berthM, berth)"
    (polygonMouseout)="onMouseOut()"
  />

  <map-info-window *ngIf="hoveredBerth" #hoverInfoWindow="mapInfoWindow" [position]="hoveredBerth?.lat">
    <dm-map-tooltip-berth-dimensions
      [berthStatuses]="
        viewType === MapViewTypes.sensors
          ? alertsBoatInfosHover
          : hoveredBerth.berth.getStatusesByViewType(this.viewType)
      "
      [berthName]="hoveredBerth.berth.data.berthName"
    />
  </map-info-window>
  <map-info-window *ngIf="selectedPylon" [position]="selectedPylon.location" #pylonInfoWindow="mapInfoWindow">
    <!--    TODO PROBABLY COMPONENT TO BE REMOVED-->
    <dm-map-map-pylon-details [pylons]="selectedPylon.pylons" />
  </map-info-window>
  <map-info-window *ngIf="selectedBerth" #clickInfoWindow="mapInfoWindow" [position]="selectedBerth?.lat">
    <dm-map-tooltip-berth-dimensions
      [berthName]="selectedBerth.berth.data.berthName"
      [berthStatuses]="viewType === MapViewTypes.sensors
          ? alertsBoatInfosSelected
          : viewType === MapViewTypes.pylons && berthBoatsPylonsInfo[selectedBerth.berth.data.berthId]
            ? boatBerthPylonType
            : selectedBerth.berth.getStatusesByViewType(this.viewType)
      "
    />
  </map-info-window>
</google-map>

<!--TODO PROBABLY COMPONENT TO BE REMOVED-->
<!--<dm-map-alerts-drawer-->
<!--  *ngIf="shouldShowAlerts && alerts?.length"-->
<!--  [@enterLeaveFromLeft]-->
<!--  [alerts]="alerts"-->
<!--  [selectedBerthId]="selectedBerth?.berth?.data?.berthId"-->
<!--  [selectedAlertId]="selectedAlertId"-->
<!--  (berthClick)="openBerthAlertInfoWindow($event)"-->
<!--/>-->

@if (selectedBerth || selectedPylon) {
  <dm-map-berth-details-drawer
    [@enterLeaveFromLeft]
    [berth]="selectedBerth ? selectedBerth.berth : null"
    [pylon]="selectedPylon ? selectedPylon : null"
    [viewType]="viewType"
    [berthBoatsPylonsInfo]="selectedBerth ? berthBoatsPylonsInfo[selectedBerth.berth.data.berthId] : null"
  />
}

<dm-ui-overflow-spinner type="fixed" [showSpinner]="pending" />
<dm-map-dynamic-legend (filtersChange)="onFiltersChange($event)" [viewType]="viewType" />
