import { Component, inject } from '@angular/core';
import { MmsOfferViewService } from '../../services/mms-offer-view.service';
import { QuotePromotionalOffers, QuoteType } from '@dm-workspace/types';

@Component({
  selector: 'dm-mms-offers-mms-offer-view-products',
  templateUrl: './mms-offer-view-products.component.html',
  styleUrl: './mms-offer-view-products.component.scss',
})
export class MmsOfferViewProductsComponent {
  readonly offerViewService = inject(MmsOfferViewService);
  protected readonly offer = this.offerViewService.offer;
  protected readonly fetchingOffer = this.offerViewService.fetching;
  protected readonly QuoteType = QuoteType;
  public offers = Object.values(QuotePromotionalOffers);
}
