import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { Currency, OrderProductType } from '@dm-workspace/types';

export interface ProductListElement {
  quantity: number;
  discountAmount?: number;
  discountName?: string;
  unit: string;
  productType: OrderProductType;
  totalPrice: number;
  totalPriceGross: number;
}

@Component({
  selector: 'dm-mms-products-summary',
  templateUrl: './mms-products-summary.component.html',
  styleUrl: './mms-products-summary.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsProductsSummaryComponent {
  currency = input.required<Currency>();
  products = input.required<ProductListElement[]>();
  totalTaxes = computed(() => this.products().reduce((p, c) => p + c.totalPrice, 0));
  totalProducts = computed(() => this.products().reduce((p, c) => p + (c.totalPriceGross - c.totalPrice), 0));
  total = computed(() => this.totalTaxes() + this.totalProducts());
}
