import { Injectable, TemplateRef, ViewContainerRef } from '@angular/core';

@Injectable()
export class MmsUiPortalService {
  private portalOutlet: ViewContainerRef | null = null;

  registerPortalOutlet(viewContainerRef: ViewContainerRef): void {
    this.portalOutlet = viewContainerRef;
  }

  unregisterPortalOutlet(): void {
    this.portalOutlet = null;
  }

  start(templateRef: TemplateRef<unknown>): void {
    this.portalOutlet?.createEmbeddedView(templateRef);
  }

  finish(): void {
    this.portalOutlet?.clear();
  }
}
