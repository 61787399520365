<button class="btn btn-primary-negative filter-btn" (click)="toggleShowLegend()" [class.is-open]="showLegend()">
  {{ "FILTERS" | translate }}<i class="i-chevron-left"></i>
</button>

<section *ngIf="showLegend()" [@fromRightAnimation]>
  <ol>
    @if (hasGroups()) {
      @for (status of groupedFilters; track status; let first = $first) {
        @if (!first) {
          <li><dm-ui-horizontal-line /></li>
        }
        <li class="group-name">{{ status.groupName | translate }}</li>
        <ng-container *ngTemplateOutlet="filtersStatus; context: { $implicit: status.filters }" />
      }
    } @else {
      <ng-container *ngTemplateOutlet="filtersStatus; context: { $implicit: filters }" />
    }
  </ol>
  <button class="btn btn-primary-negative btn-tiny" (click)="resetFilters()">
    {{ (hasSelectedAll ? "DESELECT_ALL_FILTERS" : "SELECT_ALL_FILTERS") | translate }}
  </button>
</section>
<ng-template #filtersStatus let-filters>
  @for (status of filters; track status) {
    <li>
      <dm-form-group>
        <label>
          <dm-ui-tag size="normal" [color]="status | tagColorByBerthStat">{{
            "MAP_BERTH_STATUS." + (status | uppercase) | translate
          }}</dm-ui-tag>
          <input type="checkbox" dmForm [checked]="selectedFilters?.has(status)" (click)="toggleFilter(status)" />
        </label>
      </dm-form-group>
    </li>
  }
</ng-template>
