import { ActivatedRouteSnapshot, Router, Routes } from '@angular/router';
import { MmsOffersListViewComponent } from './views/mms-offers-list-view/mms-offers-list-view.component';
import { MmsOfferViewComponent } from './views/mms-offer-view/mms-offer-view.component';
import { inject } from '@angular/core';
import { MmsOfferViewService } from './services/mms-offer-view.service';
import { MmsOfferViewSummaryComponent } from './views/mms-offer-view-summary/mms-offer-view-summary.component';
import { MmsOfferViewProductsComponent } from './views/mms-offer-view-products/mms-offer-view-products.component';
import { viewTypesList } from './views/mms-offers-list-view/mms-offers-list.config';

export const mmsOffersRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'list/readyToSend',
  },
  {
    path: 'list/:viewType',
    component: MmsOffersListViewComponent,
    canActivate: [
      (aRoute: ActivatedRouteSnapshot) => {
        if (viewTypesList.includes(aRoute.params['viewType'])) {
          return true;
        }
        inject(Router).navigate([aRoute.params['marinaCode'], 'renewals', 'list', 'readyToSend']);
        return false;
      },
    ],
  },
  {
    path: ':offerId',
    component: MmsOfferViewComponent,
    canActivate: [
      (aRoute: ActivatedRouteSnapshot) => {
        inject(MmsOfferViewService).setOfferId(aRoute.params.offerId);
      },
    ],
    canDeactivate: [
      () => {
        inject(MmsOfferViewService).onDeactivateRoute();
      },
    ],
    children: [
      {
        path: '',
        redirectTo: 'summary',
        pathMatch: 'full',
      },
      {
        path: 'summary',
        component: MmsOfferViewSummaryComponent,
      },
      {
        path: 'products',
        component: MmsOfferViewProductsComponent,
      },
    ],
  },
];
