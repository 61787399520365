import { Component, signal } from '@angular/core';
import { FiltersCollectionDirective, TimestampRangeSerialized } from '@dm-workspace/shared';
import { FormControl, FormGroup } from '@angular/forms';
import { QuotesPaymentStatus, QuoteStatus, QuoteType } from '@dm-workspace/types';
import { NgForUtils } from '@dm-workspace/utils';

export type MmsOffersFiltersValue = Partial<ReturnType<MmsOffersFiltersForm['getRawValue']>>;

export type MmsOffersFiltersForm = FormGroup<{
  customerNameFragment: FormControl<string>;
  boatNameFragment: FormControl<string>;
  dateFrom: FormControl<string>;
  dateTo: FormControl<string>;
  quoteStatus: FormControl<QuoteStatus>;
  paymentStatus: FormControl<QuotesPaymentStatus>;
  lastSentAt: FormControl<TimestampRangeSerialized>;
  acceptedAt: FormControl<TimestampRangeSerialized>;
  createdAtFrom: FormControl<string>;
  createdAtTo: FormControl<string>;
  acceptedAtFrom: FormControl<string>;
  acceptedAtTo: FormControl<string>;
  declinedAtFrom: FormControl<string>;
  declinedAtTo: FormControl<string>;
  lastSendDateFrom: FormControl<string>;
  lastSendDateTo: FormControl<string>;
  lastSeenDateFrom: FormControl<string>;
  lastSeenDateTo: FormControl<string>;
}>;

@Component({
  selector: 'dm-mms-offers-filters',
  templateUrl: './mms-offers-filters.component.html',
  styleUrls: ['./mms-offers-filters.component.scss'],
})
export class MmsOffersFiltersComponent extends FiltersCollectionDirective<FormGroup, MmsOffersFiltersValue> {
  protected readonly form = new FormGroup({
    quoteStatus: new FormControl<QuoteStatus>(null),
    createdAtFrom: new FormControl<string>(null),
    createdAtTo: new FormControl<string>(null),
    // TODO new inner type for renewals
    serviceType: new FormControl<string>(null),
    customerNameFragment: new FormControl<string>(null),
    boatNameFragment: new FormControl<string>(null),
    dateFrom: new FormControl<string>(null),
    dateTo: new FormControl<string>(null),
    paymentStatus: new FormControl<QuotesPaymentStatus>(null),
    lastSendDateFrom: new FormControl<string>(null),
    lastSendDateTo: new FormControl<string>(null),
    lastSeenDateFrom: new FormControl<string>(null),
    lastSeenDateTo: new FormControl<string>(null),
    acceptedAtFrom: new FormControl<string>(null),
    acceptedAtTo: new FormControl<string>(null),
    declinedAtFrom: new FormControl<string>(null),
    declinedAtTo: new FormControl<string>(null),

    lastSentAt: new FormControl<string>(null),
    acceptedAt: new FormControl<string>(null),
  });
  protected readonly NgForUtils = NgForUtils;
  protected readonly QuoteStatus = QuoteStatus;
  protected readonly QuotePaymentStatus = QuotesPaymentStatus;
  protected readonly quoteStatuses = signal<QuoteStatus[]>(null);
  protected readonly quoteTypes = Object.entries(QuoteType).map(([key, value]) => {
    return { key, value };
  });

  addPaymentStatus() {
    this.form.addControl('paymentStatus', new FormControl(null));
  }
  removePaymentStatus() {
    this.form.removeControl('paymentStatus');
  }

  addCreatedAt() {
    this.form.addControl('createdAtFrom', new FormControl(null));
    this.form.addControl('createdAtTo', new FormControl(null));
  }
  removeCreatedAt() {
    this.form.removeControl('createdAtFrom');
    this.form.removeControl('createdAtTo');
  }

  addAcceptedAt() {
    this.form.addControl('acceptedAtFrom', new FormControl(null));
    this.form.addControl('acceptedAtTo', new FormControl(null));
  }
  removeAcceptedAt() {
    this.form.removeControl('acceptedAtFrom');
    this.form.removeControl('acceptedAtTo');
  }

  addDeclinedAt() {
    this.form.addControl('declinedAtFrom', new FormControl(null));
    this.form.addControl('declinedAtTo', new FormControl(null));
  }
  removeDeclinedAt() {
    this.form.removeControl('declinedAtFrom');
    this.form.removeControl('declinedAtTo');
  }

  addLastSendDate() {
    this.form.addControl('lastSendDateFrom', new FormControl(null));
    this.form.addControl('lastSendDateTo', new FormControl(null));
  }
  removeLastSendDate() {
    this.form.removeControl('lastSendDateFrom');
    this.form.removeControl('lastSendDateTo');
  }

  addLastSeenDate() {
    this.form.addControl('lastSeenDateFrom', new FormControl(null));
    this.form.addControl('lastSeenDateTo', new FormControl(null));
  }
  removeLastSeenDate() {
    this.form.removeControl('lastSeenDateFrom');
    this.form.removeControl('lastSeenDateTo');
  }

  addQuoteStatus(value: QuoteStatus[]) {
    this.quoteStatuses.set(value);
    this.form.addControl('quoteStatus', new FormControl(null));

    const quoteStatusControl = this.form.controls.quoteStatus;
    if (!value.includes(quoteStatusControl.value)) {
      quoteStatusControl.setValue(null);
    }
  }
  removeQuoteStatus() {
    this.form.removeControl('quoteStatus');
  }
}
