import { Component, inject } from '@angular/core';
import { mmsLayoutNavigationRoutes, MmsNavRoutes } from '@dm-workspace/mms-panel/mms-layout';
import { FeaturesEnabledService } from '@dm-workspace/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MmsLayoutExportMarinaEnquiryComponent } from '../mms-layout-export-marina-enquiry/mms-layout-export-marina-enquiry.component';

@Component({
  selector: 'dm-mms-layout-navigation',
  templateUrl: './mms-layout-navigation.component.html',
  styleUrls: ['./mms-layout-navigation.component.scss'],
})
export class MmsLayoutNavigationComponent {
  public routes: MmsNavRoutes[] = mmsLayoutNavigationRoutes.filter(
    (navRoute) => this.featureService.hasFeatureEnabled(navRoute.feature) && !navRoute.isHidden
  );
  #modal = inject(NgbModal);

  constructor(private featureService: FeaturesEnabledService) {}

  getActionFn(label: string): void {
    switch (label) {
      case 'MARINAS_ENQUIRY_LIST_XLSX':
        this.#modal.open(MmsLayoutExportMarinaEnquiryComponent, {
          size: 'sm',
          backdrop: 'static',
          centered: true,
        });
        break;
    }
  }
}
