import { ChangeDetectionStrategy, Component, inject, Input, OnInit, signal } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'dm-mms-shared-socket-connect-modal',
  templateUrl: './mms-socket-turn-off-on-modal.component.html',
  styleUrls: ['./mms-socket-turn-off-on-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsSocketTurnOffOnModalComponent implements OnInit {
  @Input() pedestal: string;
  @Input() socketId: string;
  @Input() isUsed: boolean;
  #activeModal = inject(NgbActiveModal);
  protected apiCalling = signal<boolean>(false);
  public socketForm = inject(FormBuilder).group({
    pedestal: [],
    socketId: [],
    bookingId: [null, Validators.required],
  });
  public onSubmit() {
    this.apiCalling.set(true);
  }
  ngOnInit(): void {
    this.socketForm.patchValue({
      socketId: this.socketId,
      pedestal: this.pedestal,
    });
    if (this.isUsed) {
      this.socketForm.removeControl('bookingId');
    }
    this.socketForm.controls.pedestal.disable();
    this.socketForm.controls.socketId.disable();
  }
  public dismissModal() {
    this.#activeModal.dismiss();
  }
}
