import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Ability, CustomerType, IResourceBookingQuotation, Quote } from '@dm-workspace/types';

@Component({
  selector: 'dm-mms-table-cell-booking-customer',
  templateUrl: './mms-table-cell-booking-customer.component.html',
  styleUrls: ['./mms-table-cell-booking-customer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsTableCellBookingCustomerComponent {
  @Input({ required: true }) booking: Pick<IResourceBookingQuotation, 'customer' | 'quotationPrefilledData'> | Quote;
  protected readonly Ability = Ability;
  protected readonly CustomerType = CustomerType;
}
