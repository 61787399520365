<table class="table">
  <colgroup>
    <col />
    <col [style.width.px]="120" />
    <col [style.width.px]="120" />
    <col [style.width.px]="200" />
    <col [style.width.px]="120" />
    <col [style.width.px]="250" />
    <col [style.width.px]="200" />
    <col [style.width.px]="150" />
    <col [style.width.px]="130" />
    <col [style.width.px]="130" />
    <col [style.width.px]="155" />
    <col [style.width.px]="130" />
    <col [style.width.px]="130" />
    <col [style.width.px]="100" />
  </colgroup>

  <thead dmUiStickyElement name="orders">
    <tr>
      <th>{{ "NAME" | translate }}</th>
      <th>{{ "START_DATE" | translate }}</th>
      <th>{{ "END_DATE" | translate }}</th>
      <th>{{ "TYPE" | translate }}</th>
      <th>{{ "BOOKING_ID" | translate }}</th>
      <th>{{ "CUSTOMER" | translate }}</th>
      <th>{{ "BOAT" | translate }}</th>
      <th>{{ "STATUS" | translate }}</th>
      <th>{{ "SENT_ON" | translate }}</th>
      <th>{{ "LAST_SEEN_ON" | translate }}</th>
      <th>{{ "ACCEPTED_ON" | translate }}</th>
      <th>{{ "PAYMENT_STATUS" | translate }}</th>
      <th class="text-right">{{ "TOTAL_AMOUNT" | translate }}</th>
      <th class="text-right">{{ "ACTION" | translate }}</th>
    </tr>
  </thead>

  <tbody>
    <tr *ngFor="let offer of offers">
      <td class="name">
        <a [selectedMarinaRouterLink]="['/renewals', offer.quoteId]" returnUrlCurrentPath class="link">{{
          offer.name | empty
        }}</a>
      </td>
      <td>{{ offer.startDate | dateFormat | empty }}</td>
      <td>{{ offer.endDate | dateFormat | empty }}</td>
      <td>{{ offer.type | getKeyFromEnum: QuoteType | translate | empty }}</td>
      <td>{{ offer.booking?.humanReadableId | empty }}</td>

      <td>
        <a [routerLink]="['/customers', offer.customer?.id]" returnUrlCurrentPath class="link">
          {{ offer.customer?.name | empty }}
        </a>
      </td>

      <td>
        <a [routerLink]="['/boats', offer.boat?.id]" [state]="{ backOnClose: true }" class="link" returnUrlCurrentPath>
          {{ offer.boat?.name | empty }}
        </a>
      </td>

      <td>
        <dm-ui-tag [color]="offer.quoteStatus | quoteStatusTagColor">
          {{ offer.quoteStatus | translate | empty }}
        </dm-ui-tag>
      </td>

      <td>{{ offer.activitySummary?.lastSentAt | dateFormat | empty }}</td>
      <td>{{ offer.activitySummary?.lastSeenAt | dateFormat | empty }}</td>
      <td>{{ offer.activitySummary?.acceptedAt | dateFormat | empty }}</td>

      <td>
        <dm-ui-tag [color]="offer.order?.paymentStatus | paymentStatusTagColor">
          {{ offer.order?.paymentStatus | orderPaymentStatusTranslateKey | translate | empty }}
        </dm-ui-tag>
      </td>

      <td class="text-right">{{ offer.totalPriceGross | currency: offer.currency | empty }}</td>
      <td>
        <div class="d-flex justify-content-end align-items-center">
          <div ngbDropdown>
            <button ngbDropdownToggle type="button" class="action-button"></button>

            <div ngbDropdownMenu>
              <a ngbDropdownItem [selectedMarinaRouterLink]="['/renewals', offer.quoteId]" returnUrlCurrentPath>{{
                "DETAILS" | translate
              }}</a>

              @if (offer.actions | arrayIncludes: "SEND_OFFER") {
                <a ngbDropdownItem (click)="clickButtonSendOffer(offer, 'NATIVE_APP')">
                  {{ "SEND_MOBILE_OFFER" | translate }}
                </a>

                <a ngbDropdownItem (click)="clickButtonSendOffer(offer, 'WEB')">
                  {{ "SEND_WEB_OFFER" | translate }}
                </a>
              }

              @if (offer.actions | arrayIncludes: "RESEND_OFFER") {
                <a ngbDropdownItem (click)="clickButtonSendOffer(offer, 'NATIVE_APP')">
                  {{ "RESEND_MOBILE_OFFER" | translate }}
                </a>

                <a ngbDropdownItem (click)="clickButtonSendOffer(offer, 'WEB')">
                  {{ "RESEND_WEB_OFFER" | translate }}
                </a>
              }

              @if (offer.actions | arrayIncludes: "SEND_REMINDER") {
                <a ngbDropdownItem (click)="clickButtonSendReminderOffer(offer, 'NATIVE_APP')">
                  {{ "SEND_MOBILE_REMINDER" | translate }}
                </a>

                <a ngbDropdownItem (click)="clickButtonSendReminderOffer(offer, 'WEB')">
                  {{ "SEND_WEB_REMINDER" | translate }}
                </a>
              }

              @if (offer.actions | arrayIncludes: "SEND_PAYMENT_REMINDER") {
                <a ngbDropdownItem (click)="clickButtonSendPaymentReminder(offer)">
                  {{ "SEND_PAYMENT_REMINDER" | translate }}
                </a>
              }

              @if (offer.actions | arrayIncludes: "MARK_AS_PAID") {
                <a ngbDropdownItem (click)="clickButtonMarkOrderAsPaid(offer)">{{ "MARK_AS_PAID" | translate }}</a>
              }

              <!--            <a *ngIf="offer.quoteStatus === QuoteStatus.SENT"-->
              <!--               ngbDropdownItem-->
              <!--               (click)="clickButtonAcceptOffer(offer)"-->
              <!--            >{{ "ACCEPT_OFFER" | translate }}</a>-->

              @if (offer.actions | arrayIncludes: "DECLINE_OFFER") {
                <a ngbDropdownItem (click)="clickButtonDeclineOffer(offer)">{{ "DECLINE_OFFER" | translate }}</a>
              }

              @if (offer.actions | arrayIncludes: "CANCEL") {
                <a ngbDropdownItem (click)="clickButtonCancelOffer(offer)">{{ "CANCEL" | translate }}</a>
              }
            </div>
          </div>
        </div>
      </td>
    </tr>

    <tr *ngIf="offers?.length === 0">
      <td colspan="13">
        {{ "NO_TYPEOF_RESULTS_FOR_FILTERS" | translate: { items: "OFFERS" | translate | lowercase } }}
      </td>
    </tr>
  </tbody>
</table>
