<button type="button" class="back-link mb-4" routerLink="../" returnUrl>
  <i class="i-chevron-left"></i>
  {{ "BACK" | translate }}
</button>
<div class="d-flex justify-content-between align-items-start mb-4">
  <div>
    <h1 class="header-24 d-flex justify-content-start align-items-center">
      {{ header() }}
      <ng-content select="header-content" />
    </h1>
    @if (subHeader()) {
      <p class="color-secondary mt-2 text-sm">{{ subHeader() }}</p>
    }
  </div>
  <div>
    <ng-content select="[header-actions]" />
  </div>
</div>
@if (routes()) {
  <nav>
    @for (route of routes(); track route) {
      <a
        [routerLink]="[route.route]"
        routerLinkActive="active"
        [relativeTo]=""
        [routerLinkActiveOptions]="{ exact: !!route.route && route.route !== '.' ? false : true }"
        >{{ route.label | translate }}</a
      >
    }
  </nav>
}
