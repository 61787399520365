@if (hasItemsToShow()) {
  <section class="mb-5">
    @if (ongoingBooking()) {
      <h3 class="header-16 fw-700 mb-3">{{ "ONGOING_BOOKINGS" | translate }}</h3>
      <ng-container *ngTemplateOutlet="occupancyList; context: { $implicit: ongoingBooking() }" />
    }
    @if (occupancy().length) {
      <h3 class="header-16 fw-700 mb-3">
        {{ ongoingBooking() ? ("OCCUPANCY" | translate) : ("BOAT_PRESENCE" | translate) }}
      </h3>
      @for (item of occupancy(); track item.boatId) {
        <ng-container *ngTemplateOutlet="occupancyList; context: { $implicit: item }" />
      }
    }
  </section>
}

<ng-template #occupancyList let-item>
  @if (item.booking?.customerDisplayName) {
    <div class="d-flex py-2">
      <label for="customer" class="me-2 typo-body-m fw-500">{{ "CUSTOMER" | translate }}:</label>
      <a
        id="customer"
        class="details-link text-nowrap me-2 typo-body-m fw-500"
        [routerLink]="['/', 'customers', item.booking.customerId, 'details']"
        [state]="{ backOnClose: true }"
        returnUrlCurrentPath
      >{{ item.booking.customerDisplayName }}</a
      >
    </div>
  }

  <div class="d-flex align-items-baseline justify-content-between py-2">
    <div class="d-flex">
      <label for="booking" class="me-2 typo-body-m fw-500">{{ "BOOKING" | translate }}:</label>
      @if (!item.booking?.humanReadableId) {
        <span id="booking" class="typo-body-m fw-600 color-danger">{{ "NO_BOOKING" | translate }}</span>
      } @else {
        <div>
          <a
            class="details-link text-nowrap me-2 typo-body-m fw-500"
            [routerLink]="['/', marinaCode(), 'bookings', item.booking.id, 'details']"
            [state]="{ backOnClose: true }"
            returnUrlCurrentPath
            >{{ item.booking.humanReadableId }}</a
          >
          <span class="typo-body-m fw-600">{{ item.booking.contractType }}</span>
        </div>
      }
    </div>
    <div>
      @if (item.booking?.humanReadableId) {
        @if (!boatIsInOccupancy(item.boat.id)) {
          <button
            type="button"
            (click)="openTransferBookingModal(item.booking)"
            class="btn btn-secondary btn-secondary-outline p-2 list-action-button"
          >
            {{ "TRANSFER_BOOKING" | translate | uppercase }}
          </button>
        }
      } @else {
        <button
          type="button"
          (click)="goToQQ(item.boat.id)"
          class="btn btn-primary btn-primary-outline-outline p-2 list-action-button"
        >
          {{ "QUICK_QUOTE" | translate | uppercase }}
        </button>
      }
    </div>
  </div>

  <div class="d-flex py-2 justify-content-between align-items-center">
    <div>
      <label for="boat" class="me-2 typo-body-m fw-500">{{ "BOAT" | translate }}:</label>
      <a
        id="boat"
        class="details-link text-nowrap me-2 typo-body-m fw-500"
        [routerLink]="['/', 'boats', item.boat.id]"
        [state]="{ backOnClose: true }"
        returnUrlCurrentPath
        >{{ item.boat.name }}</a
      >
    </div>

    @if ((item.booking && item.boatIsInBerth && !item.expectedDepartures) || !item.booking) {
      <button
        type="button"
        class="btn btn-secondary btn-secondary-outline p-2 list-action-button"
        (click)="
          openBoatMovementModal({
            boat: item.boat,
            resourceId: item.resourceId
          })
        "
      >
        {{ "MOVE_BOAT" | translate }}
      </button>
    }
  </div>

  <div class="d-flex py-2 justify-content-between align-items-center">
    @if (item.boatIsInBerth) {
      <div>
        <label for="boatStatus" class="me-2 typo-body-m fw-500">{{ "BOAT_STATUS" | translate }}:</label>
        <span class="typo-body-m fw-600">{{ "BOAT_MARINA_STATUS.RETURN" | translate }}</span>
      </div>
    } @else if (item.temporaryAbsence) {
      <div>
        <label for="boatStatus" class="me-2 typo-body-m fw-500">{{ "BOAT_STATUS" | translate }}:</label>
        <span id="boatStatus" class="typo-body-m fw-600">{{
          "BOAT_MARINA_STATUS." + (item.temporaryAbsence.operation | uppercase) | translate
        }}</span>
      </div>
      @switch (item.temporaryAbsence.operation) {
        @case (BoatMovementOperation.DRY_DOCK_LIFT) {
          <button
            type="button"
            (click)="
              openBoatMovementModal({
                boat: item.boat,
                resourceId: item.resourceId,
                boatQuickReturnType: BoatMovementOperation.DRY_DOCK_LAUNCH
              })
            "
            class="btn btn-secondary btn-secondary-outline p-2 list-action-button"
          >
            {{ "RETURN_FROM_LAND" | translate | uppercase }}
          </button>
        }
        @case (BoatMovementOperation.CRUISING) {
          <button
            type="button"
            (click)="
              openBoatMovementModal({
                boat: item.boat,
                resourceId: item.resourceId,
                boatQuickReturnType: BoatMovementOperation.RETURN
              })
            "
            class="btn btn-secondary btn-secondary-outline p-2 list-action-button"
          >
            {{ "RETURN_FROM_CRUSE" | translate | uppercase }}
          </button>
        }
      }
    } @else {
      <div class="d-flex justify-content-between align-items-center">
        <label for="boatStatus" class="me-2 typo-body-m fw-500">{{ "BOAT_STATUS" | translate }}:</label>
        <span class="typo-body-m fw-600">-</span>
      </div>
      @if (item.expectedArrivals) {
        <button
          type="button"
          (click)="
            openArrivalBoatModal({
              boatId: item.boat.id,
              resourceId: item.resourceId,
              berthName: berth().data.berthName
            })
          "
          class="btn btn-secondary btn-secondary-outline p-2 list-action-button"
        >
          {{ "REPORT_ARRIVAL" | translate | uppercase }}
        </button>
      }
    }

    @if ((item.expectedDepartures && item.boatIsInBerth) || (!item.booking && !item.temporaryAbsence)) {
      <button
        type="button"
        (click)="
          openDepartureBoatModal({
            boatId: item.boat.id,
            resourceId: item.resourceId,
            berthName: berth().data.berthName
          })
        "
        class="btn btn-secondary btn-secondary-outline p-2 list-action-button"
      >
        {{ (item.expectedDepartures ? "REPORT_DEPARTURE" : "DEPART_BOAT") | translate | uppercase }}
      </button>
    }
  </div>

  @if (item.temporaryAbsence) {
    <div class="d-flex py-2 justify-content-between align-items-center">
      <div class="d-flex">
        <label for="overbookingRange" class="me-2 typo-body-m fw-500">{{ "ALLOWED_OVERBOOKING" | translate }}:</label>
        <span id="overbookingRange" class="typo-body-m fw-600"
          >{{ item.temporaryAbsence.gapStart | dateFormat }} - {{ item.temporaryAbsence.gapEnd | dateFormat }}</span
        >
      </div>
      @if (!item.temporaryAbsence.gapStart && !item.temporaryAbsence.gapEnd) {
        <button
          type="button"
          class="btn btn-secondary btn-secondary-outline py-2 px-3 list-action-button"
          (click)="openAddEditGapModal(item.temporaryAbsence)"
        >
          {{ "CREATE_GAP" | translate | uppercase }}
        </button>
      } @else {
        <button
          type="button"
          class="btn btn-secondary btn-secondary-outline py-2 px-3 list-action-button"
          (click)="openAddEditGapModal(item.temporaryAbsence)"
        >
          {{ "EDIT_GAP" | translate | uppercase }}
        </button>
      }
    </div>
  }
  <dm-ui-horizontal-line />
</ng-template>
