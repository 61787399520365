import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnInit, signal } from '@angular/core';
import { Contact, Quote } from '@dm-workspace/types';
import { ConfirmModal } from '@dm-workspace/ui';
import { MmsQuotesApiService } from '@dm-workspace/data-access';
import { NotificationService } from '@dm-workspace/notification';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiValidatorService } from '@dm-workspace/forms';
import { filter, finalize } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MmsContactChangeEmailModalComponent, MmsCustomerService } from '@dm-workspace/mms-panel/shared';
import { map, tap } from 'rxjs/operators';
import { MmsContactChangeLanguageModalComponent } from '../../../../../shared/src/lib/modals/mms-contact-change-language-modal/mms-contact-change-language-modal.component';

export type MmsOfferActionSendTarget = 'NATIVE_APP' | 'WEB';

@Component({
  selector: 'dm-mms-offers-send-modal',
  templateUrl: './mms-offer-action-send-modal.component.html',
  styleUrls: ['./mms-offer-action-send-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsOfferActionSendModalComponent extends ConfirmModal implements OnInit {
  @Input({ required: true }) public offer: Quote;
  @Input({ required: true }) public target: MmsOfferActionSendTarget;
  protected readonly apiCalling = signal<boolean>(false);
  protected readonly offerApiService = inject(MmsQuotesApiService);
  protected readonly customerService = inject(MmsCustomerService);
  protected readonly notification = inject(NotificationService);
  protected readonly apiValidator = inject(ApiValidatorService);
  protected readonly modal = inject(NgbModal);
  protected readonly cd = inject(ChangeDetectorRef);
  protected readonly customerPrimaryContact = signal<Contact | null>(null);

  public ngOnInit() {
    this.customerService
      .getCustomerPrimaryContact(this.offer.customer.id)
      .pipe(
        map(({ customerPrimaryContact }) => customerPrimaryContact),
        tap((customerPrimaryContact) => this.customerPrimaryContact.set(customerPrimaryContact))
      )
      .subscribe();
  }

  protected buttonConfirmClick() {
    this.apiCalling.set(true);

    this.offerApiService
      .send(this.offer.quoteId, { target: this.target })
      .pipe(finalize(() => this.apiCalling.set(false)))
      .subscribe({
        next: () => {
          this.notification.openSuccess();
          this.onConfirm();
        },
        error: (res: HttpErrorResponse) => {
          const notificationContent = this.apiValidator.getApiNotificationError(res);
          this.notification.openError(notificationContent);
        },
      });
  }

  protected buttonEditCustomerEmail() {
    const modalComponent = this.modal.open(MmsContactChangeEmailModalComponent, {
      size: 'xs',
    }).componentInstance as MmsContactChangeEmailModalComponent;

    modalComponent.contact = this.customerPrimaryContact();
    modalComponent.confirmed
      .pipe(
        filter(Boolean),
        tap((newContact) => {
          this.customerPrimaryContact.update((prevContact) => ({
            ...prevContact,
            email: newContact.email,
          }));
        })
      )
      .subscribe();
  }
  protected buttonEditCustomerLanguage() {
    const modalComponent = this.modal.open(MmsContactChangeLanguageModalComponent, {
      size: 'xs',
    }).componentInstance as MmsContactChangeLanguageModalComponent;

    modalComponent.contact = this.customerPrimaryContact();
    modalComponent.confirmed
      .pipe(
        filter(Boolean),
        tap((language) => {
          this.customerPrimaryContact.update((prevContact) => ({
            ...prevContact,
            language,
          }));
        })
      )
      .subscribe();
  }
}
