import { Pipe, PipeTransform } from '@angular/core';
import { BerthStatusInMarina, GroupedPylons, PylonsStatusInMarina } from '@dm-workspace/types';
import { berthsColors } from '@dm-workspace/map-utils';

const DEFAULT_PYLON = {
  fillColor: '#D9E2EB',
  strokeColor: '#506A8D',
  fillOpacity: 1,
  strokeOpacity: 1,
  strokeWeight: 1,
  zIndex: 10,
  radius: 2,
};

@Pipe({
  name: 'pylonCircle',
})
export class PylonCirclePipe implements PipeTransform {
  transform(pylon: GroupedPylons, filters: BerthStatusInMarina[]): google.maps.CircleOptions {
    if (filters.includes(pylon.status)) {
      switch (pylon.status) {
        case PylonsStatusInMarina.billableUsage:
          return {
            ...DEFAULT_PYLON,
            ...berthsColors.green,
          };
        case PylonsStatusInMarina.nonBillableUsage:
          return {
            ...DEFAULT_PYLON,
            ...berthsColors.red,
          };
        default:
          return DEFAULT_PYLON;
      }
    }

    return {
      ...DEFAULT_PYLON,
      strokeColor: '#8a8a8a',
      fillColor: '#FFFFFF',
      fillOpacity: 0.4,
      strokeOpacity: 1,
    };
  }
}
