<div [formGroup]="form">
  <h2 class="mb-l">
    {{ "CUSTOMER_DETAILS" | translate | uppercase }}
  </h2>
  <div class="row row-cols-6 gy-4">
    <dm-form-group>
      <label>{{ "FIRST_NAME" | translate }}</label>
      <input dmForm formControlName="firstName" type="text" [placeholder]="'FIRST_NAME' | translate" />
      <dm-form-error-message [control]="form.controls.firstName" />
    </dm-form-group>

    <dm-form-group>
      <label>{{ "LAST_NAME" | translate }}</label>
      <input dmForm formControlName="lastName" type="text" [placeholder]="'LAST_NAME' | translate" />
      <dm-form-error-message [control]="form.controls.lastName" />
    </dm-form-group>

    <dm-form-group>
      <label>{{ "E_MAIL" | translate }}</label>
      <input dmForm formControlName="email" type="email" [placeholder]="'E_MAIL' | translate" />
      <dm-form-error-message [control]="form.controls.email" />
    </dm-form-group>

    <dm-form-group>
      <label>{{ "PHONE" | translate }}</label>
      <input dmForm formControlName="phone" type="phone" [mask] />
      <dm-form-error-message [control]="form.controls.phone" />
    </dm-form-group>

    <dm-form-group *ngIf="form.controls.corporateType">
      <label>{{ "CUSTOMER_TYPE" | translate }}</label>
      <ng-select dmForm formControlName="corporateType">
        <ng-option *ngFor="let corporateType of CorporateType | keyvalue" [value]="corporateType.value">{{
          corporateType.value | getCustomerTypeTranslateKey | translate
        }}</ng-option>
      </ng-select>
      <dm-form-error-message [control]="form.controls.corporateType" />
    </dm-form-group>
    <dm-form-group>
      <label>{{ "PREFERRED_LANGUAGE_(EMAILS)" | translate }}</label>
      <ng-select dmForm formControlName="language">
        <ng-option *ngFor="let language of Languages" [value]="language">{{
          language | languageTranslateKey | translate
        }}</ng-option>
      </ng-select>
      <dm-form-error-message [control]="form.controls.language" />
    </dm-form-group>
  </div>

  <h2 class="my-l pt-l">
    {{ "ADDRESS_DETAILS" | translate | uppercase }}
  </h2>
  <div class="row row-cols-6 gy-4">
    <dm-form-group>
      <label>{{ "COUNTRY" | translate }}</label>
      <ng-select
        dmForm
        formControlName="countryCode"
        [placeholder]="'COUNTRY' | translate"
        [items]="COUNTRIES_NEW"
        bindValue="code"
        [searchFn]="selectSearchCountriesNewFn"
        [virtualScroll]="true"
        (ngModelChange)="onCountryCodeChange($event)"
      >
        <ng-template ng-label-tmp ng-option-tmp let-item="item">
          <strong>{{ item.code }}</strong> ({{ item.name }})
        </ng-template>
      </ng-select>
      <dm-form-error-message [control]="form.controls.countryCode" />
    </dm-form-group>

    <dm-form-group *ngIf="form.controls.stateOrProvince">
      <label>{{ "STATE" | translate }}</label>
      <ng-select
        *ngLet="profileDetailsFormDirective.states$ | async as states"
        dmForm
        bindLabel="name"
        [items]="states"
        [loading]="!states"
        formControlName="stateOrProvince"
        (change)="profileDetailsFormDirective.onStateChange()"
      />
      <dm-form-error-message controlName="state" />
    </dm-form-group>

    <dm-form-group *ngIf="form.controls.county">
      <label>{{ "COUNTY" | translate }}</label>
      <ng-select
        *ngLet="profileDetailsFormDirective.counties$ | async as counties"
        dmForm
        [readonly]="!counties"
        [items]="counties"
        bindLabel="name"
        [loading]="profileDetailsFormDirective.fetchingCounties$ | async"
        formControlName="county"
      />
      <dm-form-error-message controlName="county" />
    </dm-form-group>

    <dm-form-group>
      <label>{{ "CITY" | translate }}</label>
      <input dmForm formControlName="city" type="text" [placeholder]="'CITY' | translate" />
      <dm-form-error-message controlName="city" />
    </dm-form-group>

    <dm-form-group>
      <label>{{ "ADDRESS" | translate }}</label>
      <input dmForm formControlName="address" type="text" [placeholder]="'ADDRESS' | translate" />
      <dm-form-error-message controlName="address" />
    </dm-form-group>

    <dm-form-group>
      <label>{{ "ZIP_CODE" | translate }}</label>
      <input
        dmForm
        formControlName="zip"
        [type]="profileDetailsFormDirective.zipInputType$ | async"
        [placeholder]="'ZIP_CODE' | translate"
      />
      <dm-form-error-message controlName="zip" />
    </dm-form-group>
  </div>

  <ng-container *ngIf="showingDocuments">
    <h2 class="my-l pt-l">
      {{ "DOCUMENTS" | translate | uppercase }}
    </h2>
    <div class="row row-cols-6 gy-4">
      <dm-form-group *ngIf="form.controls.fiscalCode">
        <label>{{ "FISCAL_CODE" | translate }}</label>
        <input dmForm formControlName="fiscalCode" type="text" [placeholder]="'FISCAL_CODE' | translate" />
        <dm-form-error-message controlName="fiscalCode" />
      </dm-form-group>

      <dm-form-group *ngIf="form.controls.passport">
        <label>{{ "PASSPORT_NO" | translate }}</label>
        <input formControlName="passport" dmForm type="text" [placeholder]="'PASSPORT_NO' | translate" />
        <dm-form-error-message [control]="form.controls.passport" />
      </dm-form-group>

      <dm-form-group *ngIf="form.controls.taxExemptNumber">
        <label>{{ "TAX_REFERENCE_ID" | translate }}</label>
        <input dmForm formControlName="taxExemptNumber" type="text" [placeholder]="'TAX_REFERENCE_ID' | translate" />
        <dm-form-error-message controlName="taxExemptNumber" />
      </dm-form-group>
    </div>
  </ng-container>
</div>
