import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { Quote, QuoteDetails } from '@dm-workspace/types';
import { DATE_SHORT_YEAR_FORMAT } from '@dm-workspace/shared';

export enum QuoteViewType {
  OFFER = 'OFFER',
  ENQUIRY = 'enquiry',
}

@Component({
  selector: 'dm-mms-quote-details-header',
  templateUrl: './mms-quote-details-header.component.html',
  styleUrl: './mms-quote-details-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsQuoteDetailsHeaderComponent {
  data = input.required<QuoteDetails | Quote>({});
  routes = input.required<{ route: string; label: string }[]>({});
  viewType = input.required<QuoteViewType>({});
  protected readonly QuoteViewType = QuoteViewType;
  protected readonly DATE_SHORT_YEAR_FORMAT = DATE_SHORT_YEAR_FORMAT;
}
