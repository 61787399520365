import { Component, inject } from '@angular/core';
import { differenceInCalendarDays } from 'date-fns';
import { QuoteType } from '@dm-workspace/types';
import { MmsOfferViewService } from '../../services/mms-offer-view.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { map } from 'rxjs';
import { DATE_FORMAT, DATE_SHORT_YEAR_FORMAT } from '@dm-workspace/shared';

@Component({
  selector: 'dm-mms-offers-mms-offer-view-summary',
  templateUrl: './mms-offer-view-summary.component.html',
  styleUrl: './mms-offer-view-summary.component.scss',
})
export class MmsOfferViewSummaryComponent {
  readonly offerViewService = inject(MmsOfferViewService);
  protected readonly offer = this.offerViewService.offer;
  readonly offer$ = toObservable(this.offer);
  nights$ = this.offer$.pipe(map((data) => differenceInCalendarDays(new Date(data.endDate), new Date(data.startDate))));
  readonly todayDate = new Date();
  protected readonly QuoteType = QuoteType;
  protected readonly DATE_SHORT_YEAR_FORMAT = DATE_SHORT_YEAR_FORMAT;
  protected readonly DATE_FORMAT = DATE_FORMAT;
}
