import {
  BerthBoatAlertStatus,
  BerthDimensions,
  BerthMaintenanceStatusInMarina,
  BerthMapResponseDto,
  BerthOccupancyStatusInMarina,
  BerthPylonsStatusInMarina,
  BerthReservationsStatusInMarina,
  BerthStatusInMarina,
  ListMarinaPylonsResponseDto,
} from '@dm-workspace/types';
import {
  diffDatesInDays,
  getAlertMapStatus,
  getOccupancyMapStatus,
  getPylonsMapStatus,
  getReservationMapStatus,
  MapViewTypes,
} from '@dm-workspace/utils';

export class MapBerthClass {
  alertStatus!: BerthBoatAlertStatus[];
  occupancyStatus!: BerthOccupancyStatusInMarina[];
  reservationStatus!: BerthReservationsStatusInMarina[];
  pylonsStatus!: BerthPylonsStatusInMarina[];
  constructor(
    public data: BerthMapResponseDto,
    public dimensions: BerthDimensions,
    private marinaCode: string
  ) {
    this.data.bookings.sort((a, b) => diffDatesInDays(b.fromDate, b.toDate) - diffDatesInDays(a.fromDate, a.toDate));
    this.alertStatus = getAlertMapStatus(this.data);
    this.occupancyStatus = getOccupancyMapStatus(this.data);
    this.reservationStatus = getReservationMapStatus(this.data, this.marinaCode);
    this.pylonsStatus = [];
  }
  getStatusesByViewType(viewType: MapViewTypes): BerthStatusInMarina[] {
    if (this.data.isInMaintenance) {
      return [BerthMaintenanceStatusInMarina.disabled];
    }
    switch (viewType) {
      case MapViewTypes.occupancy:
        return this.occupancyStatus;
      case MapViewTypes.sensors:
        return this.alertStatus;
      case MapViewTypes.pylons:
        return this.pylonsStatus;
      default:
        return this.reservationStatus;
    }
  }
  visibleStatus(viewType: MapViewTypes, filters: BerthStatusInMarina[]): BerthStatusInMarina | undefined {
    return this.getStatusesByViewType(viewType).find((status) => filters.includes(status));
  }
  updatePylonStatus(pylon?: ListMarinaPylonsResponseDto) {
    this.pylonsStatus = getPylonsMapStatus(pylon);
  }
}
