import { Directive, EventEmitter, inject, Input, Output } from '@angular/core';
import { Quote } from '@dm-workspace/types';
import {
  MmsOfferActionSendModalComponent,
  MmsOfferActionSendTarget,
} from '../../modals/mms-offer-action-send-modal/mms-offer-action-send-modal.component';
import { filter } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MmsOfferActionSendReminderModalComponent } from '../../modals/mms-offer-action-send-reminder-modal/mms-offer-action-send-reminder-modal.component';
import { MmsOfferActionAcceptModalComponent } from '../../modals/mms-offer-action-accept-modal/mms-offer-action-accept-modal.component';
import { MmsOrderActionSendPaymentReminderModalComponent } from '../../../../../mms-orders/src/lib/modals/mms-order-action-send-payment-reminder-modal/mms-order-action-send-payment-reminder-modal.component';
import { MmsOrderActionMarkAsPaidModalComponent } from '../../../../../mms-orders/src/lib/modals/mms-order-action-mark-as-paid/mms-order-action-mark-as-paid-modal.component';
import {
  MmsQuoteActionCancelModalComponent,
  MmsQuoteActionDeclineModalComponent,
} from '@dm-workspace/mms-panel/shared';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderPaymentStatus, QuoteStatus, QuoteType } from '@dm-workspace/types';

@Directive()
export abstract class MmsOffersTableBase {
  @Input({ required: true }) public offers: Quote[];
  @Output() protected offersChange = new EventEmitter<void>();
  protected readonly QuoteStatus = QuoteStatus;
  protected readonly OrderPaymentStatus = OrderPaymentStatus;
  protected readonly QuoteType = QuoteType;
  readonly #modal = inject(NgbModal);

  protected clickButtonSendOffer(offer: Quote, target: MmsOfferActionSendTarget) {
    const modalComponent = this.#modal.open(MmsOfferActionSendModalComponent, {
      size: 'md',
    }).componentInstance as MmsOfferActionSendModalComponent;
    modalComponent.offer = offer;
    modalComponent.target = target;
    modalComponent.confirmed
      .pipe(
        filter(Boolean),
        tap(() => this.offersChange.emit())
      )
      .subscribe();
  }

  protected clickButtonSendReminderOffer(offer: Quote, target: MmsOfferActionSendTarget) {
    const modalComponent = this.#modal.open(MmsOfferActionSendReminderModalComponent, {
      size: 'md',
    }).componentInstance as MmsOfferActionSendReminderModalComponent;
    modalComponent.offer = offer;
    modalComponent.target = target;
    modalComponent.confirmed
      .pipe(
        filter(Boolean),
        tap(() => this.offersChange.emit())
      )
      .subscribe();
  }

  protected clickButtonAcceptOffer(offer: Quote) {
    const modalComponent = this.#modal.open(MmsOfferActionAcceptModalComponent, {
      size: 'md',
    }).componentInstance as MmsOfferActionAcceptModalComponent;
    modalComponent.offer = offer;
    modalComponent.confirmed
      .pipe(
        filter(Boolean),
        tap(() => this.offersChange.emit())
      )
      .subscribe();
  }

  protected clickButtonSendPaymentReminder(offer: Quote) {
    const modalComponent = this.#modal.open(MmsOrderActionSendPaymentReminderModalComponent, {
      size: 'md',
    }).componentInstance as MmsOrderActionSendPaymentReminderModalComponent;
    modalComponent.orderId = offer.order.id;
    modalComponent.confirmed
      .pipe(
        filter(Boolean),
        tap(() => this.offersChange.emit())
      )
      .subscribe();
  }

  protected clickButtonMarkOrderAsPaid(offer: Quote) {
    const modalComponent = this.#modal.open(MmsOrderActionMarkAsPaidModalComponent, {
      size: 'md',
    }).componentInstance as MmsOrderActionMarkAsPaidModalComponent;
    modalComponent.orderId = offer.order.id;
    modalComponent.confirmed
      .pipe(
        filter(Boolean),
        tap(() => this.offersChange.emit())
      )
      .subscribe();
  }

  protected clickButtonCancelOffer(offer: Quote) {
    const modalComponent = this.#modal.open(MmsQuoteActionCancelModalComponent, { size: 'lg' })
      .componentInstance as MmsQuoteActionCancelModalComponent;
    modalComponent.quote = offer;
    modalComponent.isCustomer = !!offer.customer;
    modalComponent.confirmed
      .pipe(
        filter(Boolean),
        tap(() => this.offersChange.emit())
      )
      .subscribe();
  }

  protected clickButtonDeclineOffer(offer: Quote) {
    const modalComponent = this.#modal.open(MmsQuoteActionDeclineModalComponent, {
      size: 'md',
    }).componentInstance as MmsQuoteActionDeclineModalComponent;
    modalComponent.quote = offer;
    modalComponent.confirmed
      .pipe(
        filter(Boolean),
        tap(() => this.offersChange.emit())
      )
      .subscribe();
  }
}
