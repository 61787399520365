import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MmsUiFullPageHeaderComponent } from './components/mms-ui-full-page-header/mms-ui-full-page-header.component';
import { MmsUiFullPageComponent } from './components/mms-ui-full-page/mms-ui-full-page.component';
import { MmsUiFullPageFooterComponent } from './components/mms-ui-full-page-footer/mms-ui-full-page-footer.component';
import { RouterModule } from '@angular/router';
import { MmsUiFullPageSidebarComponent } from './components/mms-ui-full-page-sidebar/mms-ui-full-page-sidebar.component';
import { MmsUiPageHeaderComponent } from './components/mms-ui-details-header/mms-ui-page-header.component';
import { TranslateModule } from '@ngx-translate/core';
import { MmsUiPortalOutletDirective } from './directives/mms-ui-portal-outlet.directive';
import { MmsUiPortalToDirective } from './directives/mms-ui-portal-to.directive';
import { LibsSharedModule } from '@dm-workspace/shared';

const declarations = [
  MmsUiFullPageSidebarComponent,
  MmsUiFullPageHeaderComponent,
  MmsUiFullPageComponent,
  MmsUiPageHeaderComponent,
  MmsUiFullPageFooterComponent,
  MmsUiPortalOutletDirective,
  MmsUiPortalToDirective,
];

@NgModule({
  imports: [CommonModule, RouterModule, TranslateModule, LibsSharedModule],
  declarations: [...declarations],
  exports: [...declarations],
})
export class MmsUiModule {}
