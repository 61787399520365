<dm-ui-animated-fixed-header [showHiddenContent]="false">
  <div hidden-content>
    <form [formGroup]="form" class="pt-xl" (reset)="resetForm()">
      <div class="row row-cols-6 gy-4">
        @if (form.controls.quoteStatus) {
          <dm-form-group>
            <label>{{ "STATUS" | translate }}</label>
            <ng-select [placeholder]="'ALL' | translate" formControlName="quoteStatus">
              @for (option of quoteStatuses(); track option) {
                <ng-option [value]="option">
                  {{ option | getKeyFromEnum: QuoteStatus | translate }}
                </ng-option>
              }
            </ng-select>
            <dm-form-error-message controlName="quoteStatus" />
          </dm-form-group>
        }

        @if (form.controls.createdAtFrom && form.controls.createdAtTo) {
          <dm-form-group class="col-4">
            <label>{{ "CREATED_AT" | translate }}</label>
            <div class="row gx-3 flex flex-no-wrap align-items-center">
              <div class="col">
                <dm-form-input-icon icon="calendar">
                  <input
                    dmInputDatepicker
                    dmForm
                    formControlName="createdAtFrom"
                    ngbDatepicker
                    [placeholder]="'START' | translate"
                    [dmInputMaxDate]="form.value.createdAtTo | modifyDateDays: -1"
                    type="text"
                    datepickerClass="modal-z-index"
                    container="body"
                  />
                </dm-form-input-icon>
                <dm-form-error-message controlName="createdAtFrom" />
              </div>
              <span class="col-auto">-</span>
              <div class="col">
                <dm-form-input-icon icon="calendar">
                  <input
                    dmInputDatepicker
                    dmForm
                    formControlName="createdAtTo"
                    ngbDatepicker
                    [placeholder]="'END' | translate"
                    [dmInputMinDate]="form.value.createdAtFrom | modifyDateDays: 1"
                    type="text"
                    datepickerClass="modal-z-index"
                    container="body"
                  />
                </dm-form-input-icon>
                <dm-form-error-message controlName="createdAtTo" />
              </div>
            </div>
          </dm-form-group>
        }

        <dm-form-group>
          <label>{{ "SERVICE_TYPE" | translate }}</label>
          <ng-select [placeholder]="'ALL' | translate" formControlName="serviceType">
            @for (option of quoteTypes; track option.value) {
              <ng-option [value]="option.value">
                {{ option.key | translate }}
              </ng-option>
            }
          </ng-select>
          <dm-form-error-message controlName="serviceType" />
        </dm-form-group>

        <dm-form-group>
          <label>{{ "CUSTOMER" | translate }}</label>
          <input type="text" dmForm [placeholder]="'ALL' | translate" formControlName="customerNameFragment" />
          <dm-form-error-message controlName="customerNameFragment" />
        </dm-form-group>

        <dm-form-group>
          <label>{{ "BOAT" | translate }}</label>
          <input type="text" dmForm [placeholder]="'ALL' | translate" formControlName="boatNameFragment" />
          <dm-form-error-message controlName="boatNameFragment" />
        </dm-form-group>

        <dm-form-group class="col-4">
          <label>{{ "PERIOD" | translate }}</label>
          <div class="row gx-3 flex flex-no-wrap align-items-center">
            <div class="col">
              <dm-form-input-icon icon="calendar">
                <input
                  dmInputDatepicker
                  dmForm
                  formControlName="dateFrom"
                  ngbDatepicker
                  [placeholder]="'START' | translate"
                  [dmInputMaxDate]="form.value.dateTo | modifyDateDays: -1"
                  type="text"
                  datepickerClass="modal-z-index"
                  container="body"
                />
              </dm-form-input-icon>
              <dm-form-error-message controlName="dateFrom" />
            </div>
            <span class="col-auto">-</span>
            <div class="col">
              <dm-form-input-icon icon="calendar">
                <input
                  dmInputDatepicker
                  dmForm
                  formControlName="dateTo"
                  ngbDatepicker
                  [placeholder]="'END' | translate"
                  [dmInputMinDate]="form.value.dateFrom | modifyDateDays: 1"
                  type="text"
                  datepickerClass="modal-z-index"
                  container="body"
                />
              </dm-form-input-icon>
              <dm-form-error-message controlName="dateTo" />
            </div>
          </div>
        </dm-form-group>

        @if (form.controls.lastSendDateTo && form.controls.lastSendDateFrom) {
          <dm-form-group class="col-4">
            <label>{{ "LAST_SEND_DATE" | translate }}</label>
            <div class="row gx-3 flex flex-no-wrap align-items-center">
              <div class="col">
                <dm-form-input-icon icon="calendar">
                  <input
                    dmInputDatepicker
                    dmForm
                    formControlName="lastSendDateFrom"
                    ngbDatepicker
                    [placeholder]="'START' | translate"
                    [dmInputMaxDate]="form.value.lastSendDateTo | modifyDateDays: -1"
                    type="text"
                    datepickerClass="modal-z-index"
                    container="body"
                  />
                </dm-form-input-icon>
                <dm-form-error-message controlName="lastSendDateFrom" />
              </div>
              <span class="col-auto">-</span>
              <div class="col">
                <dm-form-input-icon icon="calendar">
                  <input
                    dmInputDatepicker
                    dmForm
                    formControlName="lastSendDateTo"
                    ngbDatepicker
                    [placeholder]="'END' | translate"
                    [dmInputMinDate]="form.value.lastSendDateFrom | modifyDateDays: 1"
                    type="text"
                    datepickerClass="modal-z-index"
                    container="body"
                  />
                </dm-form-input-icon>
                <dm-form-error-message controlName="lastSendDateTo" />
              </div>
            </div>
          </dm-form-group>
        }

        @if (form.controls.lastSeenDateTo && form.controls.lastSeenDateFrom) {
          <dm-form-group class="col-4">
            <label>{{ "LAST_SEEN_DATE" | translate }}</label>
            <div class="row gx-3 flex flex-no-wrap align-items-center">
              <div class="col">
                <dm-form-input-icon icon="calendar">
                  <input
                    dmInputDatepicker
                    dmForm
                    formControlName="lastSeenDateFrom"
                    ngbDatepicker
                    [placeholder]="'START' | translate"
                    [dmInputMaxDate]="form.value.lastSeenDateTo | modifyDateDays: -1"
                    type="text"
                    datepickerClass="modal-z-index"
                    container="body"
                  />
                </dm-form-input-icon>
                <dm-form-error-message controlName="lastSeenDateFrom" />
              </div>
              <span class="col-auto">-</span>
              <div class="col">
                <dm-form-input-icon icon="calendar">
                  <input
                    dmInputDatepicker
                    dmForm
                    formControlName="lastSeenDateTo"
                    ngbDatepicker
                    [placeholder]="'END' | translate"
                    [dmInputMinDate]="form.value.lastSeenDateFrom | modifyDateDays: 1"
                    type="text"
                    datepickerClass="modal-z-index"
                    container="body"
                  />
                </dm-form-input-icon>
                <dm-form-error-message controlName="lastSeenDateTo" />
              </div>
            </div>
          </dm-form-group>
        }

        @if (form.controls.acceptedAtTo && form.controls.acceptedAtFrom) {
          <dm-form-group class="col-4">
            <label>{{ "ACCEPTED_AT" | translate }}</label>
            <div class="row gx-3 flex flex-no-wrap align-items-center">
              <div class="col">
                <dm-form-input-icon icon="calendar">
                  <input
                    dmInputDatepicker
                    dmForm
                    formControlName="acceptedAtFrom"
                    ngbDatepicker
                    [placeholder]="'START' | translate"
                    [dmInputMaxDate]="form.value.acceptedAtTo | modifyDateDays: -1"
                    type="text"
                    datepickerClass="modal-z-index"
                    container="body"
                  />
                </dm-form-input-icon>
                <dm-form-error-message controlName="acceptedAtFrom" />
              </div>
              <span class="col-auto">-</span>
              <div class="col">
                <dm-form-input-icon icon="calendar">
                  <input
                    dmInputDatepicker
                    dmForm
                    formControlName="acceptedAtTo"
                    ngbDatepicker
                    [placeholder]="'END' | translate"
                    [dmInputMinDate]="form.value.lastSendDateFrom | modifyDateDays: 1"
                    type="text"
                    datepickerClass="modal-z-index"
                    container="body"
                  />
                </dm-form-input-icon>
                <dm-form-error-message controlName="acceptedAtTo" />
              </div>
            </div>
          </dm-form-group>
        }

        @if (form.controls.declinedAtFrom && form.controls.declinedAtTo) {
          <dm-form-group class="col-4">
            <label>{{ "DECLINED_AT" | translate }}</label>
            <div class="row gx-3 flex flex-no-wrap align-items-center">
              <div class="col">
                <dm-form-input-icon icon="calendar">
                  <input
                    dmInputDatepicker
                    dmForm
                    formControlName="declinedAtFrom"
                    ngbDatepicker
                    [placeholder]="'START' | translate"
                    [dmInputMaxDate]="form.value.declinedAtTo | modifyDateDays: -1"
                    type="text"
                    datepickerClass="modal-z-index"
                    container="body"
                  />
                </dm-form-input-icon>
                <dm-form-error-message controlName="declinedAtFrom" />
              </div>
              <span class="col-auto">-</span>
              <div class="col">
                <dm-form-input-icon icon="calendar">
                  <input
                    dmInputDatepicker
                    dmForm
                    formControlName="declinedAtTo"
                    ngbDatepicker
                    [placeholder]="'END' | translate"
                    [dmInputMinDate]="form.value.declinedAtFrom | modifyDateDays: 1"
                    type="text"
                    datepickerClass="modal-z-index"
                    container="body"
                  />
                </dm-form-input-icon>
                <dm-form-error-message controlName="declinedAtTo" />
              </div>
            </div>
          </dm-form-group>
        }

        @if (form.controls.paymentStatus) {
          <dm-form-group>
            <label>{{ "PAYMENT_STATUS" | translate }}</label>
            <ng-select [placeholder]="'ALL' | translate" dmForm formControlName="paymentStatus">
              <ng-option
                *ngFor="let option of QuotePaymentStatus | keyvalue: NgForUtils.originalOrder"
                [value]="option.key"
              >
                {{ option.value | orderPaymentStatusTranslateKey | translate }}
              </ng-option>
            </ng-select>
            <dm-form-error-message controlName="paymentStatus" />
          </dm-form-group>
        }
      </div>
    </form>
  </div>

  <button class="reset-button" type="reset" (click)="resetForm()" filters-additions *ngIf="filtersCount()">
    {{ "RESET_ALL_FILTERS" | translate }} ({{ filtersCount() }})
  </button>
</dm-ui-animated-fixed-header>
