<div class="card px-xxl py-xl">
  <div class="card header mb-2 py-3" dmUiStickyElement name="dashboard">
    <h3 class="text-m">
      {{ "CHECK_INS" | translate | uppercase }}
    </h3>

    <dm-ui-toggle-switch [items]="views" [selectItemIndex]="selectedIndex" />
  </div>
  <div class="position-relative" [style.min-height.px]="300">
    <dm-mms-dashboard-list-done header="🥳" *ngIf="hasBookingsAllDone">
      {{ "MMS_DASHBOARD.CHECK_INS_DONE.0" | translate }}
      {{ "MMS_DASHBOARD.CHECK_INS_DONE.1" | translate }}
    </dm-mms-dashboard-list-done>

    <ng-container *ngIf="hasBookingsTodo">
      <dm-mms-dashboard-bookings-list [bookings]="bookings" [actionRef]="action" [viewMode]="viewMode" />

      <dm-ui-pagination
        [rows]="pagination.limit"
        (pageChange)="onPageChange($event)"
        (numberOfRowsChange)="onLimitChange($event)"
        [collectionSize]="pagination.totalCount"
      />
    </ng-container>

    <ng-template #action let-booking="booking">
      <a
        *ngIf="viewMode?.value !== 'checkinsPerformedOffline'"
        [selectedMarinaRouterLink]="['/bookings', booking.id, 'check-in', 'documents']"
        [state]="{ backOnClose: true }"
        returnUrlCurrentPath
        class="btn-pill-primary-light"
        >{{ "CHECK_IN" | translate }}</a
      >

      <div ngbDropdown class="d-inline-block list-dropdown" *ngIf="viewMode?.value === 'checkinsPerformedOffline'">
        <button type="button" class="action-button" id="actionsDropdown" ngbDropdownToggle></button>
        <div ngbDropdownMenu aria-labelledby="actionsDropdown">
          <a
            ngbDropdownItem
            [selectedMarinaRouterLink]="['/bookings', booking.id, 'check-in', 'documents']"
            [state]="{ backOnClose: true }"
            returnUrlCurrentPath
            >{{ "CHECK_IN_FROM_DASHBOARD" | translate }}</a
          >
          <a
            ngbDropdownItem
            *ngIf="viewMode.value === 'checkinsPerformedOffline'"
            (click)="changeStatus(booking, quotationStatuses.CHECKED_OFFLINE, true)"
            >{{ "CHECK_IN_PERFORMED_OFFLINE" | translate }}</a
          >
        </div>
      </div>
    </ng-template>

    <dm-ui-overflow-spinner [showSpinner]="pending" type="absolute" />
  </div>
</div>
