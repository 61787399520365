<dm-map-berth-status
  *ngIf="berthStatuses || berthName"
  [berthStatuses]="berthStatuses"
  [name]="berthName"
/>

<ng-container *ngIf="berthDimensions">
  <p class="note">{{ "ALLOWED_BOAT_DIMENSIONS" | translate }}</p>
  <div class="illustration">
    <img src="/assets/dimensions.svg" />
    <span class="item width"
      ><span>{{ berthDimensions.minAllowedBoatWidth }} - {{ berthDimensions.maxAllowedBoatWidth }}&nbsp;M</span></span
    >
    <span class="item length"
      ><span>{{ berthDimensions.minAllowedBoatLength }} - {{ berthDimensions.maxAllowedBoatLength }} M</span></span
    >
    <span class="item depth"
      ><span>{{ berthDimensions.depth }} M</span></span
    >
  </div>
</ng-container>
