
@if(berthStatuses.length > 0){
  <ng-template *ngTemplateOutlet="berthStatus" />
} @else {
  <ng-template *ngTemplateOutlet="berthStatusEmpty" />
}

<ng-template #berthStatus>
  <div class="d-flex align-items-center p-3">
    <div class="d-flex flex-grow-1 me-2">
      <dm-map-popup-row [label]="'PIER_BERTH' | translate" [txt]="name" [size]="size" />
    </div>
    <div class="statuses">
      @for (status of berthStatuses; track status) {
        <dm-ui-tag
          [color]="status | tagColorByBerthStat"
          size="normal"
          *ngIf="status"
          [ngClass]="{ 'mx-1': berthStatuses.length > 1 }"
        >
          {{ "MAP_BERTH_STATUS." + status | translate }}
        </dm-ui-tag>
      }
    </div>
  </div>
</ng-template>

<ng-template #berthStatusEmpty>
  <div class="d-flex align-items-center p-3">
    <div class="d-flex flex-grow-1 me-2">
      <dm-map-popup-row [label]="'PIER_BERTH' | translate" [txt]="name" [size]="size" />
    </div>
    <div class="statuses">
      <dm-ui-tag [color]="'black-light'" size="normal">
        {{ "MAP_BERTH_STATUS.EMPTY" | translate }}
      </dm-ui-tag>
    </div>
  </div>
</ng-template>
