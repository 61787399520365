@if (hasItemsToShow()) {
  <section class="mb-5">
    <h3 class="header-16 fw-700 mb-3">{{ "ONGOING_BOOKINGS" | translate }}</h3>

    @for (boatBooking of bookingListBoats(); track boatBooking.boat.id) {
      @if (boatBooking.customerId) {
        <div class="d-flex py-2">
          <label for="customer" class="me-2 typo-body-m fw-500">{{ "CUSTOMER" | translate }}:</label>
          <a
            id="customer"
            class="details-link text-nowrap me-2 typo-body-m fw-500"
            [routerLink]="['/', 'customers', boatBooking.customerId, 'details']"
            [state]="{ backOnClose: true }"
            returnUrlCurrentPath
            >{{ boatBooking.customerDisplayName }}</a
          >
        </div>
      }

      <div class="d-flex align-items-baseline py-2">
        <label for="booking" class="me-2 typo-body-m fw-500">{{ "BOOKING" | translate }}:</label>
        <div id="booking" class="d-flex align-items-baseline w-100 justify-content-between">
          @if (boatBooking.id) {
            <div>
              <a
                class="details-link text-nowrap me-2 typo-body-m fw-500"
                [routerLink]="['/', marinaCode(), 'bookings', boatBooking.id, 'details']"
                [state]="{ backOnClose: true }"
                returnUrlCurrentPath
                >{{ boatBooking.humanReadableId }}</a
              >
              <span class="typo-body-m fw-600">{{ boatBooking.contractType }}</span>
            </div>
            @if (!boatIsInOccupancy(boatBooking.boat.id)) {
              <button
                type="button"
                (click)="openTransferBookingModal(boatBooking)"
                class="btn btn-secondary btn-secondary-outline p-2 list-action-button"
              >
                {{ "TRANSFER_BOOKING" | translate | uppercase }}
              </button>
            }
          } @else {
            <span class="typo-body-m fw-600 color-danger">{{ "NO_BOOKING" | translate }}</span>
            <button
              type="button"
              (click)="goToQQ(boatBooking.boat.id)"
              class="btn btn-primary btn-primary-outline-outline p-2 list-action-button"
            >
              {{ "QUICK_QUOTE" | translate | uppercase }}
            </button>
          }
        </div>
      </div>

      <div class="d-flex py-2 justify-content-between align-items-center">
        <div>
          <label for="boat" class="me-2 typo-body-m fw-500">{{ "BOAT" | translate }}:</label>
          <a
            id="boat"
            class="details-link text-nowrap me-2 typo-body-m fw-500"
            [routerLink]="['/', 'boats', boatBooking.boat.id]"
            [state]="{ backOnClose: true }"
            returnUrlCurrentPath
            >{{ boatBooking.boat.name }}</a
          >
        </div>
        @if (boatIsInOccupancy(boatBooking.boatId)) {
          <button
            type="button"
            (click)="openBoatMovementModal(boatBooking)"
            class="btn btn-secondary btn-secondary-outline p-2 list-action-button"
          >
            {{ "MOVE_BOAT" | translate | uppercase }}
          </button>
        }
      </div>

      <div class="d-flex py-2">
        <label for="boatStatus" class="me-2 typo-body-m fw-500">{{ "BOAT_STATUS" | translate }}:</label>
        @if (boatIsInOccupancy(boatBooking.boatId)) {
          <span class="typo-body-m fw-600">{{ "BOAT_MARINA_STATUS.RETURN" | translate }}</span>
        } @else if (temporaryAbsence(boatBooking.boatId)) {
          <span id="boatStatus" class="typo-body-m fw-600">{{
            "BOAT_MARINA_STATUS." + (temporaryAbsence(boatBooking.boatId).operation | uppercase) | translate
          }}</span>
        } @else {
          -
        }
      </div>

      @if (temporaryAbsence(boatBooking.boat.id); as temporaryAbsence) {
        <div class="d-flex py-2 justify-content-between align-items-center">
          <div class="d-flex">
            <label for="overbookingRange" class="me-2 typo-body-m fw-500"
              >{{ "ALLOWED_OVERBOOKING" | translate }}:</label
            >
            <span id="overbookingRange" class="typo-body-m fw-600"
              >{{ temporaryAbsence.gapStart | dateFormat }} - {{ temporaryAbsence.gapEnd | dateFormat }}</span
            >
          </div>
          @if (!temporaryAbsence.gapStart && !temporaryAbsence.gapEnd) {
            <button
              type="button"
              class="btn btn-secondary btn-secondary-outline py-2 px-3 list-action-button"
              (click)="openAddEditGapModal(temporaryAbsence)"
            >
              {{ "CREATE_GAP" | translate | uppercase }}
            </button>
          } @else {
            <button
              type="button"
              class="btn btn-secondary btn-secondary-outline py-2 px-3 list-action-button"
              (click)="openAddEditGapModal(temporaryAbsence)"
            >
              {{ "EDIT_GAP" | translate | uppercase }}
            </button>
          }
        </div>
      }
      <dm-ui-horizontal-line />
    }
  </section>
}
