<div class="modal-header">
  <h2 class="title">{{ "SEND_OFFER" | translate }}</h2>
</div>

<div class="modal-body">
  <dm-form-input-value>
    <p header class="d-flex justify-content-between gap-2">
      <span>{{ "CUSTOMERS_PRIMARY_CONTACT_EMAIL" | translate }}</span>
      <button (click)="buttonEditCustomerEmail()" class="btn btn-primary-negative btn-tiny" type="button">
        <i class="i-edit"></i>{{ "EDIT" | translate }}
      </button>
    </p>
    <p body>{{ customerPrimaryContact()?.email }}</p>
    <p footer>{{ "YOU_CAN_EDIT_THE_EMAIL_ADDRESS_TO_WHICH_THE_OFFER_SHOULD_BE_SENT" | translate }}</p>
  </dm-form-input-value>
  <dm-ui-horizontal-line />
  <dm-form-input-value>
    <p header class="d-flex justify-content-between gap-2">
      <span>{{ "EMAIL_WILL_BE_SENT_IN" | translate }}</span>
      <button (click)="buttonEditCustomerLanguage()" class="btn btn-primary-negative btn-tiny" type="button">
        <i class="i-edit"></i>{{ "EDIT" | translate }}
      </button>
    </p>
    <p body>{{ customerPrimaryContact()?.language | languageTranslateKey | translate }}</p>
    <p footer>{{ "YOU_CAN_EDIT_LANGUAGE_DESCRIPTION" | translate }}</p>
  </dm-form-input-value>
  <dm-ui-horizontal-line />

  <dm-ui-info>
    <i icon class="i-exclamation-mark-circle"></i>
    <ng-container body>
      <p>{{ "SENDING_EMAIL_WARNING.0" | translate: { item: "OFFER" | translate | lowercase } }}</p>
      <p class="mt-2">{{ "SENDING_EMAIL_WARNING.1" | translate }}</p>
      <p class="mt-2">{{ "SENDING_EMAIL_WARNING.2" | translate }}</p>
      <p class="mt-2">{{ "SENDING_EMAIL_WARNING.3" | translate }}</p>
    </ng-container>
  </dm-ui-info>
</div>

<div class="modal-footer justify-content-between d-flex">
  <button type="button" class="btn btn-secondary-negative" (click)="onCancel()">
    {{ "CANCEL" | translate }}
  </button>
  <button type="button" class="btn btn-primary" (click)="buttonConfirmClick()" [disabled]="apiCalling()">
    <dm-ui-spinner class="me-1" [showSpinner]="apiCalling()" />
    {{ "SEND_OFFER" | translate }}
  </button>
</div>
