import { Component, inject, signal } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FileUtil, FileUtilResponse, NgForUtils } from '@dm-workspace/utils';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormUtils } from '@dm-workspace/forms';
import { MmsQuotesApiService } from '@dm-workspace/data-access';
import { catchError, take } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { NotificationService } from '@dm-workspace/notification';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiValidator } from '@dm-workspace/shared';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MarinaTypes } from '@dm-workspace/types';

@Component({
  selector: 'dm-mms-layout-export-marina-enquiry',
  templateUrl: './mms-layout-export-marina-enquiry.component.html',
  styleUrl: './mms-layout-export-marina-enquiry.component.scss',
})
export class MmsLayoutExportMarinaEnquiryComponent {
  apiCalling = signal<boolean>(false);
  #quoteService = inject(MmsQuotesApiService);
  #notificationService = inject(NotificationService);
  #aModal = inject(NgbActiveModal);
  form = new FormGroup({
    dateFrom: new FormControl<string>('', Validators.required),
    dateTo: new FormControl<string>({ value: '', disabled: true }, Validators.required),
    marinaType: new FormControl<MarinaTypes>(null, Validators.required),
  });

  readonly MarinaTypes = MarinaTypes;

  constructor() {
    this.form.controls.dateFrom.statusChanges.pipe(takeUntilDestroyed()).subscribe((status) => {
      if (status === 'VALID') {
        this.form.controls.dateTo.enable();
        this.form.controls.dateTo.updateValueAndValidity();
      }
    });
  }

  onCancel() {
    this.#aModal.close();
  }

  exportButtonClick() {
    if (this.apiCalling() || !FormUtils.isValid(this.form)) {
      return;
    }
    this.apiCalling.set(true);
    const { dateFrom, dateTo, marinaType } = this.form.getRawValue();
    const filename = `enquiry_export_${dateFrom.replace(/-/g, '')}_${dateTo.replace(/-/g, '')}_${marinaType}.xlsx`;
    this.#quoteService
      .navbarExportQuotesXlsx(this.form.getRawValue())
      .pipe(
        take(1),
        catchError((res: HttpErrorResponse) => {
          this.#notificationService.openError(ApiValidator.getApiNotificationError(res));
          return throwError(() => res);
        })
      )
      .subscribe((file: FileUtilResponse) => {
        FileUtil.saveBlob(file.blob, file.fileName || filename);
        this.apiCalling.set(false);
        this.#aModal.close();
      });
  }

  protected readonly NgForUtils = NgForUtils;
}
