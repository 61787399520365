import { Directive, inject, OnDestroy, TemplateRef } from '@angular/core';
import { MmsUiPortalService } from '@dm-workspace/mms-panel/mms-ui';

@Directive({
  selector: '[dmMmsUiPortalTo]',
})
export class MmsUiPortalToDirective implements OnDestroy {
  #templateRef = inject(TemplateRef<unknown>);
  #portalService = inject(MmsUiPortalService);
  constructor() {
    this.#portalService.start(this.#templateRef);
  }

  ngOnDestroy(): void {
    this.#portalService.finish();
  }
}
