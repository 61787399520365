import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { TagColor } from '@dm-workspace/ui';

export enum WaitingListResourceStatus {
  available = 'AVAILABLE',
  reserved = 'Reserved',
}

@Component({
  selector: 'dm-mms-table-resource-status',
  templateUrl: './mms-table-resource-status.component.html',
  styleUrls: ['./mms-table-resource-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsTableResourceStatusComponent {
  public statuses = WaitingListResourceStatus;
  status = input<WaitingListResourceStatus>(undefined);
  color = computed<TagColor | undefined>(() => {
    switch (this.status()) {
      case WaitingListResourceStatus.available:
        return 'green-light';
      case WaitingListResourceStatus.reserved:
        return 'red-light';
      default:
        return undefined;
    }
  });
}
