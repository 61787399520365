<h4>{{ "PYLON" | translate }}: {{ pylons()[0].pylonName }}</h4>
<div class="table-wrapper">
  <table class="table table--no-border table--compact">
    <thead>
      <tr>
        <th>{{ "Nr." | translate }}</th>
        <th>{{ "TYPE" | translate }}</th>
        <th>{{ "STATUS" | translate }}</th>
        <th>{{ "BOOKING_ID" | translate }}</th>
        <th>{{ "BOAT" | translate }}</th>
      </tr>
    </thead>
    <tbody>
      @for (pylon of pylons(); track pylon) {
        <tr>
          <td>{{ pylon.outputName }}</td>
          <td>
            <span [class]="pylon.mediaType === 'electricity' ? 'i-plug-in' : 'i-drop'"></span>
          </td>
          <td>
            @if (pylon.isUsed && !!pylon.bookingReferenceId) {
              <dm-ui-tag color="green-light">{{ "ON" | translate }}</dm-ui-tag>
            } @else if (pylon.isUsed) {
              <dm-ui-tag color="red-light">{{ "ON" | translate }}</dm-ui-tag>
            } @else {
              <dm-ui-tag color="grey-light">{{ "OFF" | translate }}</dm-ui-tag>
            }
          </td>
          <td>
            <p>{{ pylon.bookingReferenceId | empty }}</p>
          </td>
          <td>{{ pylon.boat?.name | empty }}</td>
        </tr>
      }
    </tbody>
  </table>
</div>
