@if (pylon()) {
  <div class="d-flex justify-content-between align-items-center mb-4">
    <dm-map-popup-row [label]="'PYLON' | translate" [txt]="pylon().name" size="l" />
    @if (pylon().status === pylonStatus.nonBillableUsage) {
      <dm-ui-tag color="red-light">{{ "NON-BILLABLE_USAGE" | translate }}</dm-ui-tag>
    } @else if (pylon().status === pylonStatus.billableUsage) {
      <dm-ui-tag color="green-light">{{ "BILLABLE_USAGE" | translate }}</dm-ui-tag>
    }
  </div>
  <dm-map-pedestal-details-list
    [pylons]="pylon().pylons"
    [marinaCode]="marinaCode()"
    (changeSocketConnectionStatus)="onChangeConnectStatus($event)"
  />
} @else {
  @if (hasItemsToShow()) {
    <h3 class="header-16 fw-700 mb-3">{{ "UTILITIES_CONNECTION_STATUS" | translate }}</h3>
    @for (bookingPylon of bookingsPylonsList(); track bookingPylon.id) {
      @if (bookingPylon.customerDisplayName) {
        <div class="d-flex py-2">
          <label for="customer" class="me-2 typo-body-m fw-500">{{ "CUSTOMER" | translate }}:</label>
          <a
            id="customer"
            class="details-link text-nowrap me-2 typo-body-m fw-500"
            [routerLink]="['/', 'customers', bookingPylon.customerId, 'details']"
            [state]="{ backOnClose: true }"
            returnUrlCurrentPath
          >{{ bookingPylon.customerDisplayName }}</a
          >
        </div>
      }

      <div class="d-flex align-items-baseline justify-content-between py-2">
        <div class="d-flex">
          <label for="booking" class="me-2 typo-body-m fw-500">{{ "BOOKING" | translate }}:</label>
          @if (!bookingPylon.id) {
            <span id="booking" class="typo-body-m fw-600 color-danger">{{ "NO_BOOKING" | translate }}</span>
          } @else {
            <div>
              <a
                class="details-link text-nowrap me-2 typo-body-m fw-500"
                [routerLink]="['/', marinaCode(), 'bookings', bookingPylon.id, 'details']"
                [state]="{ backOnClose: true }"
                returnUrlCurrentPath
                >{{ bookingPylon.humanReadableId }}</a
              >
              <span class="typo-body-m fw-600">{{ bookingPylon.contractType }}</span>
            </div>
          }
        </div>
      </div>

      <div class="d-flex py-2">
        <div>
          <label for="boat" class="me-2 typo-body-m fw-500">{{ "BOAT" | translate }}:</label>
          <a
            id="boat"
            class="details-link text-nowrap me-2 typo-body-m fw-500"
            [routerLink]="['/', 'boats', bookingPylon.boatId]"
            [state]="{ backOnClose: true }"
            returnUrlCurrentPath
            >{{ bookingPylon.boat?.name }}</a
          >
        </div>
      </div>

      <div class="d-flex align-items-baseline justify-content-between py-2">
        <div>
          <label for="utilities" class="me-2 typo-body-m fw-500">{{ "UTILITIES_BILLING_METHOD" | translate }}:</label>
          <span id="utilities" class="typo-body-m fw-600">{{
            getBoatHaveUtilities(bookingPylon) ? (getBoatHaveUtilities(bookingPylon).billingType | translate) : "-"
          }}</span>
        </div>
        @if (getBoatHaveUtilities(bookingPylon)) {
          @if (!bookingPylon.pylonConnection?.isUsed) {
            <button
              type="button"
              class="btn btn-secondary btn-secondary-outline py-2 px-3 list-action-button"
              (click)="openPylonConnectionModal(bookingPylon)"
            >
              {{ "CONNECT" | translate | uppercase }}
            </button>
          }
        } @else {
          <button
            type="button"
            class="btn btn-secondary btn-secondary-outline py-2 px-3 list-action-button"
            (click)="openEnableUtilitiesBookingModal(bookingPylon)"
          >
            {{ "ENABLE_UTILITIES" | translate | uppercase }}
          </button>
        }
      </div>
      <dm-ui-horizontal-line />
    }
  }
}
