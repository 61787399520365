<div ngbDropdown class="ml-auto d-inline-block list-dropdown position-relative">
  <button
    [disabled]="pending"
    ngbDropdownToggle
    type="button"
    class="btn btn-primary-negative btn-primary-negative--transparent btn-tiny ml-s no-chevron"
  >
    <dm-ui-spinner [showSpinner]="pending" size="xs" color="primary" />
    <span class="i-send"></span>
    {{ "RESEND_TO_CRM" | translate }}
  </button>

  <div ngbDropdownMenu aria-labelledby="actionsDropdown">
    <button *ngIf="orderProductId" ngbDropdownItem type="button" (click)="resendOrderProductToServiceBus()">
      {{ "ORDER_PRODUCT" | translate }}
    </button>
    <button *ngIf="orderId" ngbDropdownItem type="button" (click)="resendOrderToServiceBus()">
      {{ "ORDER" | translate }}
    </button>
    <button *ngIf="booking" ngbDropdownItem type="button" (click)="resendBookingToServiceBus()">
      {{ "BOOKING" | translate }}
    </button>
  </div>
</div>
