<button type="button" class="back-link mb-4" routerLink="../" returnUrl>
  <i class="i-chevron-left"></i>
  {{ "BACK" | translate }}
</button>
@if(data()) {
  <div class="d-flex justify-content-between align-items-start mb-4">
    <div>
      <h1 class="header-24 d-flex justify-content-start align-items-center">
        {{ viewType() | uppercase | translate }}
        <dm-ui-tag [color]="data().quoteStatus | quoteStatusTagColor" class="me-3 ms-4" *ngIf="data()?.quoteStatus">{{
            data().quoteStatus | translate | uppercase
          }}</dm-ui-tag>
      </h1>
      @if (viewType() === QuoteViewType.OFFER) {
        <p class="color-secondary mt-2 text-sm">
          {{ "RENEWAL" | translate }} {{ data().booking?.humanReadableId }} {{ data().startDate | dateFormat : DATE_SHORT_YEAR_FORMAT }} -
          {{ data().endDate | dateFormat : DATE_SHORT_YEAR_FORMAT }}
        </p>
      } @else {
        <p class="color-secondary mt-2 text-sm">{{ data()?.name }}</p>
      }
    </div>
    <div>
      <ng-content />
    </div>
  </div>
}
<dm-mms-quote-details-nav [routes]="routes()" />
