<div [style.max-width.px]="720">
  <dm-ui-card color="blue" class="position-relative">
    <dm-ui-card-header icon="i-marina-task"
      ><h3 class="header-16">
        {{ "MOORING_SUMMARY_AND_DISCOUNTS_CONFIGURATION" | translate }}
      </h3></dm-ui-card-header
    >
    <dm-ui-card-body>
      <div class="mx-4 mt-3 color-secondary typo-body-s">
        <h6>{{ "OFFER_SUMMARY" | translate }}</h6>
        <dm-ui-horizontal-line />
      </div>

      @if (offer()?.products) {
        <div class="px-l pb-m">
          @for (product of offer()?.products; track product) {
            <div class="d-flex justify-content-between align-items-center py-2">
              <div class="d-flex align-items-start">
                <span class="i-office"></span>
                <div>
                  <h4 class="header-12">
                    {{
                      product.product === null && product?.product.name
                        ? product.product.name
                        : (product.product.name | translate)
                    }}
                  </h4>
                  <p class="nights">
                    {{ product.quantity }}
                    <small
                      >{{ product.unit | translate | uppercase }}
                      <span>• {{ "MANDATORY PRODUCT" | translate | uppercase }}</span></small
                    >
                  </p>
                </div>
              </div>
              <dl>
                <dt>
                  <strong>{{ "TOTAL_AMOUNT" | translate }}</strong>
                </dt>
                <dd>
                  <strong>{{ product.totalPrice + product.vatAmount | currency: offer().currency }}</strong>
                </dd>
              </dl>
            </div>
          }
        </div>
      }
      <div class="mx-4 mb-4">
        <dm-ui-horizontal-line />
      </div>
      <div class="p-m mooring-summary d-flex justify-content-between align-items-start">
        <div>
          <h4>{{ "SUMMARY" | translate }}</h4>
          <p class="color-secondary text-sm mt-2 fw-600">
            {{ offer()?.products.length }} {{ (offer()?.products.length === 1 ? "PRODUCT" : "PRODUCTS") | translate }}
          </p>
        </div>
        <dl>
          <dt>{{ "PRODUCTS_&_SERVICES" | translate }}</dt>
          <dd>{{ offer()?.totalPrice | currency: offer()?.currency }}</dd>
          <dt>{{ "TAXES" | translate }}</dt>
          <dd>{{ offer()?.totalPriceGross - offer()?.totalPrice | currency: offer()?.currency }}</dd>
          <dt class="mb-0 mt-3">
            <strong>{{ "TOTAL_AMOUNT" | translate }}</strong>
          </dt>
          <dd class="mb-0 mt-3">
            <strong> {{ offer()?.totalPriceGross | currency: offer()?.currency }}</strong>
          </dd>
        </dl>
      </div>

      <dm-ui-overflow-spinner [showSpinner]="fetchingOffer()" type="absolute" />
    </dm-ui-card-body>
  </dm-ui-card>
</div>
