<div class="modal-header">
  <h2 class="title">{{ "CONNECT_BOOKING_TO_SOCKETS" | translate }}</h2>
</div>
<div class="modal-body">
  <p>
    {{ "CONNECT_BOOKING_TO_SOCKETS_DESCRIPTION" | translate }} <strong>{{ humanReadableId }}</strong>
  </p>

  <dm-form-group class="w-50 my-3">
    <label>{{ "PEDESTAL" | translate }}</label>
    <input dmForm [formControl]="pedestalForm" type="text" [placeholder]="'PEDESTAL' | translate" />
    <dm-form-error-message [control]="pedestalForm" />
  </dm-form-group>
  @for (pylonSocketsMediaType of pylonSocketsGroupedByMediaTypes | keyvalue; track pylonSocketsMediaType.key) {
    <h5 class="color-secondary mt-4 mb-3">
      {{ "MEDIA_TYPES_VALUES." + pylonSocketsMediaType.key | uppercase | translate }}
    </h5>
    <div class="d-flex gap-2">
      <button
        *ngFor="let pylonSocket of pylonSocketsMediaType.value"
        type="button"
        class="btn btn-secondary btn-secondary-outline btn-media"
        [class.selected]="selectedSocket.get(pylonSocketsMediaType.key) === pylonSocket"
        [disabled]="!pylonSocket.isAvailable"
        (click)="selectSocket(pylonSocket, pylonSocketsMediaType.key)"
      >
        {{ pylonSocket.channel }}
        <span [class]="icons[pylonSocket.mediaType]"></span>
      </button>
    </div>
  }
</div>

<div class="modal-footer justify-content-between d-flex">
  <button type="button" class="btn btn-tertiary btn-narrow" (click)="dismissModal()">
    {{ "CANCEL" | translate }}
  </button>
  <button class="btn btn-primary btn-default" type="button" (click)="onSubmit()" [disabled]="apiCalling()">
    <dm-ui-spinner class="me-2" [showSpinner]="apiCalling()" />
    {{ "CONFIRM" | translate }}
  </button>
</div>
<dm-ui-overflow-spinner [showSpinner]="apiCalling()" type="absolute" />
