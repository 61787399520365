import { ChangeDetectionStrategy, Component, inject, Input, signal } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PylonMediaType, PylonSocket } from '@dm-workspace/types';
import { FormControl, Validators } from '@angular/forms';
import { PylonUtils } from '@dm-workspace/utils';

@Component({
  selector: 'dm-mms-shared-socket-connect-modal',
  templateUrl: './mms-socket-connect-modal.component.html',
  styleUrls: ['./mms-socket-connect-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsSocketConnectModalComponent {
  #activeModal = inject(NgbActiveModal);
  protected selectedSocket: Map<string, PylonSocket> = new Map();
  protected apiCalling = signal<boolean>(true);
  @Input() humanReadableId: string;
  public pedestalForm = new FormControl<string>(null, Validators.required);
  public onSubmit() {
    this.apiCalling.set(true);
  }
  icons = PylonUtils.icons;
  pylonSocketsGroupedByMediaTypes = PylonUtils.groupSocketByMediaType([
    {
      mediaType: PylonMediaType.ELECTRICITY,
      channel: '1',
      isUsed: true,
      isAvailable: false,
    },
    {
      mediaType: PylonMediaType.ELECTRICITY,
      channel: '2',
      isUsed: true,
      isAvailable: true,
    },
    {
      mediaType: PylonMediaType.ELECTRICITY,
      channel: '3',
      isUsed: true,
      isAvailable: true,
    },
    {
      mediaType: PylonMediaType.ELECTRICITY,
      channel: '4',
      isUsed: true,
      isAvailable: true,
    },
    {
      mediaType: PylonMediaType.WATER,
      channel: '1',
      isUsed: true,
      isAvailable: true,
    },
    {
      mediaType: PylonMediaType.WATER,
      channel: '2',
      isUsed: true,
      isAvailable: true,
    },
    {
      mediaType: PylonMediaType.WATER,
      channel: '3',
      isUsed: true,
      isAvailable: true,
    },
    {
      mediaType: PylonMediaType.WATER,
      channel: '4',
      isUsed: true,
      isAvailable: true,
    },
  ]);
  public dismissModal() {
    this.#activeModal.dismiss();
  }

  protected selectSocket(socket: PylonSocket, key: string) {
    this.selectedSocket.set(key, socket);
  }
}
