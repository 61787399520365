<table class="table">
  <colgroup>
    @for (col of columns; track col) {
      <col [class]="col | lowercase" [style.width.px]="133" />
    }
  </colgroup>

  <thead dmUiStickyElement name="offerList" [secondElementOffset]="110" [style.z-index]="'2'">
    <tr>
      @for (col of columns; track col) {
        <th [class]="col | lowercase">{{ col | uppercase | translate }}</th>
      }
    </tr>
  </thead>

  <tbody>
    @for (offer of offers; track offer.quoteId) {
      <tr>
        <!--STATUS-->
        <td>
          <dm-ui-tag [color]="offer.quoteStatus | quoteStatusTagColor">
            {{ offer.quoteStatus | translate | empty }}
          </dm-ui-tag>
        </td>
        <!--CREATED_AT-->
        <td>
          {{ offer.createdAt | dateFormat: DATE_SHORT_YEAR_FORMAT }}<br />
          {{ offer.createdAt | dateFormat: "time" }}
        </td>
        <!--SERVICE_TYPE-->
        <td>
          {{ offer.type | getKeyFromEnum: QuoteType | translate | empty }}
          @if(offer.promotionalOffers && offer.promotionalOffers.includes(QuotePromotionalOffers.HAPPY_BERTH_DAYS)){
            <dm-ui-tag [color]="'yellow-light'" class="mt-1" [style.border-radius]="'2px'">
              {{ "HAPPY_BERTHS_DAYS" | translate  }}
            </dm-ui-tag>
          }
        </td>
        <!--CUSTOMER-->
        <td>
          <a [routerLink]="['/customers', offer.customer?.id]" returnUrlCurrentPath class="link">
            {{ offer.customer?.name | empty }}
          </a>
        </td>
        <!--RESOURCE-->
        <td>
          {{ offer.proposedResource?.name || resourceAvailability[offer.quoteId]?.resource?.name | empty }}
          @if (!offer.proposedResource && resourceAvailability) {
            <dm-mms-table-resource-status class="ms-2" [status]="resourceAvailability[offer.quoteId]?.status" />
          }
        </td>
        <!--BOAT-->
        <td>
          <dm-mms-table-cell-boat [boat]="offer.boat || (offer.descriptionParsed?.boat | quoteDescriptionBoatToBoat)" />
        </td>
        <!--PERIOD-->
        <td>
          <dm-mms-table-cell-start-end [start]="offer.startDate" [end]="offer.endDate" />
        </td>

        @if (selectedOfferTable.length === 1) {
          @switch (selectedOfferTable[0]) {
            @case (QuoteStatus.READY_TO_SEND) {
              <ng-container *ngTemplateOutlet="validUntil; context: { offer }" />
              <ng-container *ngTemplateOutlet="paymentStatus; context: { offer }" />
            }
            @case (QuoteStatus.SENT) {
              <ng-container *ngTemplateOutlet="validUntil; context: { offer }" />
            }
            @case (QuoteStatus.ACCEPTED) {
              <ng-container *ngTemplateOutlet="acceptedAt; context: { offer }" />
            }
            @case (QuoteStatus.DECLINED) {
              <ng-container *ngTemplateOutlet="declinedAt; context: { offer }" />
            }
          }
        } @else {
          <ng-container *ngTemplateOutlet="validUntil; context: { offer }" />
          <ng-container *ngTemplateOutlet="paymentStatus; context: { offer }" />
        }
        <ng-container *ngTemplateOutlet="actions; context: { offer }" />
      </tr>
    }
    <tr *ngIf="offers?.length === 0">
      <td colspan="13">
        {{ "NO_TYPEOF_RESULTS_FOR_FILTERS" | translate: { items: "OFFERS" | translate | lowercase } }}
      </td>
    </tr>
  </tbody>
</table>

<ng-template #paymentStatus let-offer="offer">
  <td>
    <dm-ui-tag [color]="offer.order?.paymentStatus | paymentStatusTagColor">
      {{ offer.order?.paymentStatus | orderPaymentStatusTranslateKey | translate | empty }}
    </dm-ui-tag>
  </td>
</ng-template>

<ng-template #validUntil let-offer="offer">
  <td>
    <p class="mb-1">
      {{ offer.expirationDate | dateFormat: DATE_SHORT_YEAR_FORMAT | empty }}
    </p>
    <p class="color-secondary">
      {{ "LAST_CONTRACT" | translate | lowercase }}
    </p>
    <!--            [class.color-danger]="enquiry.activitySummary?.contactAttempts?.count >= 3"-->
    <strong class="color-black">
      {{ offer.activitySummary?.lastSeenAt | dateFormat: DATE_TIME_FORMAT_MINUTES | empty }}
    </strong>
  </td>
</ng-template>

<ng-template #declinedAt let-offer="offer">
  <td>{{ offer.declinedAt || offer.expirationDate | dateFormat: DATE_SHORT_YEAR_FORMAT }}<br /></td>
</ng-template>

<ng-template #acceptedAt let-offer="offer">
  <td>{{ offer.activitySummary?.acceptedAt | dateFormat: DATE_SHORT_YEAR_FORMAT }}<br /></td>
</ng-template>

<ng-template #actions let-offer="offer">
  <td>
    <div class="d-flex justify-content-center align-items-center">
      <div ngbDropdown>
        <button ngbDropdownToggle type="button" class="action-button"></button>

        <div ngbDropdownMenu>
          <a ngbDropdownItem [selectedMarinaRouterLink]="['/renewals', offer.quoteId]" returnUrlCurrentPath>{{
            "DETAILS" | translate
          }}</a>

          @if (offer.actions | arrayIncludes: "SEND_OFFER") {
            <a ngbDropdownItem (click)="clickButtonSendOffer(offer, 'NATIVE_APP')">
              {{ "SEND_MOBILE_OFFER" | translate }}
            </a>

            <a ngbDropdownItem (click)="clickButtonSendOffer(offer, 'WEB')">
              {{ "SEND_WEB_OFFER" | translate }}
            </a>
          }

          @if (offer.actions | arrayIncludes: "RESEND_OFFER") {
            <a ngbDropdownItem (click)="clickButtonSendOffer(offer, 'NATIVE_APP')">
              {{ "RESEND_MOBILE_OFFER" | translate }}
            </a>

            <a ngbDropdownItem (click)="clickButtonSendOffer(offer, 'WEB')">
              {{ "RESEND_WEB_OFFER" | translate }}
            </a>
          }

          @if (offer.actions | arrayIncludes: "SEND_REMINDER") {
            <a ngbDropdownItem (click)="clickButtonSendReminderOffer(offer, 'NATIVE_APP')">
              {{ "SEND_MOBILE_REMINDER" | translate }}
            </a>

            <a ngbDropdownItem (click)="clickButtonSendReminderOffer(offer, 'WEB')">
              {{ "SEND_WEB_REMINDER" | translate }}
            </a>
          }

          @if (offer.actions | arrayIncludes: "SEND_PAYMENT_REMINDER") {
            <a ngbDropdownItem (click)="clickButtonSendPaymentReminder(offer)">
              {{ "SEND_PAYMENT_REMINDER" | translate }}
            </a>
          }

          @if (offer.actions | arrayIncludes: "MARK_AS_PAID") {
            <a ngbDropdownItem (click)="clickButtonMarkOrderAsPaid(offer)">{{ "MARK_AS_PAID" | translate }}</a>
          }

          <!--            <a *ngIf="offer.quoteStatus === QuoteStatus.SENT"-->
          <!--               ngbDropdownItem-->
          <!--               (click)="clickButtonAcceptOffer(offer)"-->
          <!--            >{{ "ACCEPT_OFFER" | translate }}</a>-->

          @if (offer.actions | arrayIncludes: "DECLINE_OFFER") {
            <a ngbDropdownItem (click)="clickButtonDeclineOffer(offer)">{{ "DECLINE_OFFER" | translate }}</a>
          }

          @if (offer.actions | arrayIncludes: "CANCEL") {
            <a ngbDropdownItem (click)="clickButtonCancelOffer(offer)">{{ "CANCEL" | translate }}</a>
          }
        </div>
      </div>
    </div>
  </td>
</ng-template>
