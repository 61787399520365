import { Directive, inject, OnDestroy, ViewContainerRef } from '@angular/core';
import { MmsUiPortalService } from '../services/mms-ui-portal.service';

@Directive({
  selector: '[dmMmsUiPortalOutlet]',
})
export class MmsUiPortalOutletDirective implements OnDestroy {
  #viewContainerRef = inject(ViewContainerRef);
  #portalService = inject(MmsUiPortalService);
  constructor() {
    this.#portalService.registerPortalOutlet(this.#viewContainerRef);
  }

  ngOnDestroy(): void {
    this.#portalService.unregisterPortalOutlet();
  }
}
