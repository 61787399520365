import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

import {
  BerthAvailabilityReservationDto,
  BoatMovementOperation,
  GroupedPylons,
  PylonsStatusInMarina,
  BoatPylonInfo,
} from '@dm-workspace/types';
import { BerthDrawerDetailsClass } from '../berth-drawer-details.class';
import { MapBerthClass } from '@dm-workspace/marina-map';

@Component({
  selector: 'dm-map-berth-details-pylons-list',
  templateUrl: './map-berth-details-pylons-list.component.html',
  styleUrl: '../map-berth-details-list-styles.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapBerthDetailsPylonsListComponent extends BerthDrawerDetailsClass {
  berth = input.required<MapBerthClass>();
  pylon = input.required<GroupedPylons>();
  marinaCode = input.required<string>();
  extendedBookingsData = input.required<BerthAvailabilityReservationDto[]>();
  berthBoatsPylonsInfo = input.required<BoatPylonInfo[]>();
  hasItemsToShow = computed(() => this.berth().data.bookings.length);
  bookingsPylonsList = computed<BerthAvailabilityReservationDto[]>(() => {
    return [...(this.extendedBookingsData() ?? [])].map((item) => {
      return {
        ...item,
        pylonConnection: this.berthBoatsPylonsInfo()?.find((pylon) => pylon.id === item.boatId) ?? null,
      };
    });
  });
  readonly pylonStatus = PylonsStatusInMarina;

  public getBoatHaveUtilities(bookingPylon: BerthAvailabilityReservationDto) {
    return bookingPylon.bookedProducts.find((products) => products.product !== 'MOORING');
  }

  protected readonly BoatMovementOperation = BoatMovementOperation;
}
