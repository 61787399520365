import { InjectionToken, Provider } from '@angular/core';
import { IEnvironment } from '@dm-workspace/environments';
import { ENV } from '@dm-workspace/types';

export type MmsMarinasSupportView =
  | 'offers'
  | 'sensors'
  | 'postpaid'
  | 'journalTasks'
  | 'planner'
  | 'pylons'
  | 'enquiry';

export type MmsMarinasSupportViewsConfig = {
  [key in MmsMarinasSupportView]?: MmsMarinasSupportViewsConfigItem;
};

export type MmsMarinasSupportViewsConfigItem = {
  supportedMarinas?: string[] | '*';
  unsupportedMarinas?: string[] | '*';
};

export const MMS_MARINAS_SUPPORT_VIEWS_CONFIG_TOKEN = new InjectionToken<MmsMarinasSupportViewsConfig>(
  'MMS_MARINAS_SUPPORT_VIEWS_CONFIG_TOKEN'
);

export const MmsMarinasSupportViewsConfigProvider: Provider = {
  provide: MMS_MARINAS_SUPPORT_VIEWS_CONFIG_TOKEN,
  useFactory: (env: IEnvironment): MmsMarinasSupportViewsConfig => {
    return {
      offers: {
        unsupportedMarinas: ['IV04'],
      },
      sensors: {
        unsupportedMarinas: [],
      },
      postpaid: {
        unsupportedMarinas: ['IV04'],
      },
      journalTasks: {
        supportedMarinas: '*',
      },
      pylons: {
        unsupportedMarinas: ['IV04', 'FC01'],
      },
      planner: {
        unsupportedMarinas: ['IV04'],
      },
      enquiry: {
        supportedMarinas: (() => {
          if (env.production) {
            return ['MD12', 'MT13', 'MS14', 'MB11', 'AM01', 'FC01', 'DI02', 'GC03', 'TU01', 'GO03', 'LE04', 'ZE02'];
          }
        })(),
        unsupportedMarinas: (() => {
          if (env.production) {
            return '*';
          }
          return ['IV04'];
        })(),
      },
    };
  },
  deps: [ENV],
};
