<div class="modal-header px-4">
  <h2 class="title">{{ "MARINAS_ENQUIRY_LIST_XLSX" | translate }}</h2>
</div>

<div class="modal-body px-4">
  <form [formGroup]="form">
    <dm-form-group>
      <label>{{ "DATE" | translate }}</label>
      <div class="row flex flex-no-wrap align-items-center">
        <div class="col">
          <dm-form-input-icon icon="calendar">
            <input
              dmForm
              dmInputDatepicker
              formControlName="dateFrom"
              ngbDatepicker
              [dmInputMaxDate]="this.form.controls.dateTo.value"
              [placeholder]="'START' | translate"
              type="text"
              datepickerClass="modal-z-index"
              container="body"
            />
          </dm-form-input-icon>
          <dm-form-error-message controlName="dateFrom" />
        </div>
        <span class="col-auto">-</span>
        <div class="col">
          <dm-form-input-icon icon="calendar">
            <input
              dmForm
              dmInputDatepicker
              formControlName="dateTo"
              ngbDatepicker
              [disabled]="!this.form.controls.dateFrom.valid"
              [dmInputMinDate]="this.form.controls.dateFrom.value"
              [placeholder]="'END' | translate"
              type="text"
              datepickerClass="modal-z-index"
              container="body"
            />
          </dm-form-input-icon>
          <dm-form-error-message controlName="dateTo" />
        </div>
      </div>
    </dm-form-group>

    <div class="mt-4">
      <h4 class="header-12">{{ "SELECT_MARINA_TYPE" | translate }}</h4>
      <dm-form-radio-group class="d-flex mt-2 flex-column gap-0" [formControl]="form.controls.marinaType">
        @for (marinaType of MarinaTypes | keyvalue: NgForUtils.originalOrder; track marinaType) {
          <dm-form-radio-card
            [value]="marinaType.value"
            [name]="'marinaType_' + marinaType"
            class="border-0 p-0 align-items-center"
          >
            <span class="typo-body-m"
              >{{ marinaType.value | uppercase | translate | titlecase }} {{ "MARINAS" | translate | lowercase }}</span
            >
          </dm-form-radio-card>
        }
        <dm-form-error-message controlName="marinaType" />
      </dm-form-radio-group>
    </div>
  </form>
</div>

<div class="modal-footer justify-content-between d-flex px-4">
  <button type="button" class="btn btn-secondary-negative" (click)="onCancel()">
    {{ "CANCEL" | translate }}
  </button>
  <button type="button" class="btn btn-primary" (click)="exportButtonClick()">
    <dm-ui-spinner class="me-1" [showSpinner]="apiCalling()" />
    {{ "EXPORT" | translate }}
  </button>
</div>
