@if (berth()) {
  <div class="container container-status">
    <dm-map-berth-status
      size="l"
      [name]="berth().data.berthName"
      [berthStatuses]="
      viewType() === viewTypes.sensors ? getBoatsAlertStatuses(berth().data) : berth().getStatusesByViewType(viewType())
    "
    />
  </div>
  <div class="image-wrapper">
    <h4 class="mb-4">{{ "ALLOWED_BOAT_DIMENSIONS" | translate }}</h4>
    <dm-ui-berth-dimensions [dimensions]="berth().dimensions" />
  </div>
}
<div class="container lists">
  @switch (viewType()) {
    @case (viewTypes.reservations) {
      @if (!isLoading()) {
        <dm-map-map-berth-details-reservations-list
          [berth]="berth()"
          [extendedBookingsData]="extendedBookingsData()"
          [marinaCode]="marinaCode$ | async"
        />
      } @else {
        <dm-ui-spinner [showSpinner]="isLoading()" color="primary" />
      }
    }
    @case (viewTypes.occupancy) {
      @if (!isLoading()) {
        <dm-map-map-berth-details-occupancy-list
          [berth]="berth()"
          [extendedBookingsData]="extendedBookingsData()"
          [marinaCode]="marinaCode$ | async"
        />
      } @else {
        <dm-ui-spinner [showSpinner]="isLoading()" color="primary" />
      }
    }
    @case (viewTypes.sensors) {
      @if (!isLoading()) {
        <dm-map-berth-details-sensor-list
          [berth]="berth()"
          [extendedBookingsData]="extendedBookingsData()"
          [marinaCode]="marinaCode$ | async"
        />
      } @else {
        <dm-ui-spinner [showSpinner]="isLoading()" color="primary" />
      }
    }
    @case (viewTypes.pylons) {
      @if (!isLoading()) {
        <dm-map-berth-details-pylons-list
          [berth]="berth()"
          [pylon]="pylon()"
          [extendedBookingsData]="extendedBookingsData()"
          [berthBoatsPylonsInfo]="berthBoatsPylonsInfo()"
          [marinaCode]="marinaCode$ | async"
        />
      } @else {
        <dm-ui-spinner [showSpinner]="isLoading()" color="primary" />
      }
    }
  }
</div>
