<form (ngSubmit)="buttonConfirmClick()" [formGroup]="form">
  <div class="modal-header">
    <h2 class="title">{{ "EDIT_LANGUAGE" | translate }}</h2>
  </div>

  <div class="modal-body">
    <dm-form-group>
      <label>{{ "PREFERRED_LANGUAGE_(EMAILS)" | translate }}</label>
      <ng-select dmForm formControlName="language">
        <ng-option *ngFor="let language of Languages" [value]="language">{{
          language | languageTranslateKey | translate
        }}</ng-option>
      </ng-select>
      <dm-form-error-message [control]="form.controls.language" />
    </dm-form-group>
  </div>

  <div class="modal-footer justify-content-between d-flex">
    <button class="btn btn-tertiary btn-narrow" type="button" (click)="onCancel()">
      {{ "CANCEL" | translate }}
    </button>
    <button type="submit" class="btn btn-primary" [disabled]="apiCalling()">
      <dm-ui-spinner class="me-2" [showSpinner]="apiCalling()" />
      {{ "SAVE" | translate }}
    </button>
  </div>
</form>
